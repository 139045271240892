import React, { useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";
import dealReg from "../../../../images/dealReg.svg";
import "./deal.css";
import { getPlansAll, getStates } from "../../../../apis/DealRegApis";

function DealRegistration({ dispatch }) {
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [plans, setPlans] = useState([]);
  const [custplan, setCustPlans] = useState({
    no_license: 0,
    planrequested: {
      name: "",
      price: 0,
      validfor: "",
      noofdays: 0,
      features: [],
    },
    currentplan: "",
  });
  const [custcompany, setCustcompany] = useState({
    companyname: "",
    state: "",
    companyaddress: "",
    countryCode: "+91",
  });

  useEffect(() => {
    const fetchPlans = async () => {
      const resState = await getStates("India");
      const resplans = await getPlansAll();
      if (!resplans) {
        console.log("getting plans Has Some Error");
      }
      if (!resState) {
        console.log("getting States Has Some Error");
      }
      await setStates(resState.states);
      await setPlans(resplans);
    };
    fetchPlans();
  }, []);

  const endcustomercompanydetails = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "no_license") setCustPlans({ ...custplan, [name]: value });
    else if (name === "plans") {
      await plans.forEach((plan) => {
        if (plan._id.valueOf() === value) {
          const planrequested = {
            name: plan.name,
            price: plan.price,
            validfor: plan.validfor,
            noofdays: plan.noofdays,
            features: plan.features,
          };
          return setCustPlans({
            ...custplan,
            planrequested: planrequested,
            currentplan: plan._id,
          });
        }
      });
    } else setCustcompany({ ...custcompany, [name]: value });
  };

  const Submit_Form = (e) => {
    e.preventDefault();
    const plandetails = { plans: [custplan] };
    dispatch({ type: "ADD_CUSTOMERDETAILS", payload: custcompany });
    dispatch({ type: "ADD_PLAN_DETAILS", payload: plandetails });
    navigate("/home/dealregistration/next");
  };

  return (
    <div className="deal-register">
      <form className="nva-head" onSubmit={Submit_Form}>
        <div className="nav-headers">
          <p>Please Fill the End Customer Details</p>
        </div>
        <div>
          <label>
            Company Name: <b>*</b>
          </label>
          <input
            name="companyname"
            onChange={endcustomercompanydetails}
            value={custcompany.companyname}
            required
            type="text"
          />
        </div>
        <div>
          <label>Company Address:</label>
          <input
            type="text"
            name="companyaddress"
            onChange={endcustomercompanydetails}
            value={custcompany.companyaddress}
          />
        </div>

        <div>
          <label>
            State: <b>*</b>
          </label>
          <select
            id="country"
            onChange={endcustomercompanydetails}
            value={custcompany.state}
            name="state"
            required
          >
            <option value="">Select One</option>
            {states.map((state, index) => {
              return (
                <option key={index} value={state.name}>
                  {state.name}
                </option>
              );
            })}
          </select>
        </div>

        <div>
          <label>
            Enter No. of Licenses <b>*</b>
          </label>
          <input
            type="number"
            id="country"
            name="no_license"
            onChange={endcustomercompanydetails}
            value={custplan.no_license}
            required
          />
        </div>
        <div>
          <label>
            Choose Plans <b>*</b>
          </label>
          <select
            id="country"
            name="plans"
            required
            value={custplan.currentplan}
            onChange={endcustomercompanydetails}
          >
            <option value="">Plans</option>
            {plans.map((plan, index) => {
              return (
                <option key={index} value={plan._id}>
                  {plan.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="deal-buttons">
          <NavLink className={"button"} to="/home/dealinfo/">
            Back
            <BsArrowLeftShort />
          </NavLink>
          <button type="submit" className={"button"}>
            Submit
          </button>
        </div>
      </form>
      <img src={dealReg} alt="" />
    </div>
  );
}

export default DealRegistration;
