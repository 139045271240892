import React, { useEffect, useReducer, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Footer from "./Footer/footer";
import Header from "./Header/header";
import CompanyDetails from "./CompanyDetails/companyDetails";
import Agreement from "./Agreement/agreement";
import Submision from "./Submition/Submision";
import PartnershipType from "./PartnershipType/partnershipType";
import { checkPartnerLogin } from "../../../apis/PartnersAPI";
import PartnerReducer from "./PartnerReducer";

const partnerInitialState = {
  partnershipType: {},
  companyDetails: {},
  contactInformation: {},
  questions: {},
  aggrement: {},
  step: 0,
  isLodding: false,
  isError: false,
};

const PartnerDash = () => {
  const [state, dispatch] = useReducer(PartnerReducer, partnerInitialState);
  const [logedin, setloged] = useState(false);
  const [partner, setPartner] = useState({});
  const naviagte = useNavigate();
  useEffect(() => {
    const checklogin = async () => {
      const res = await checkPartnerLogin();
      if (!res) {
        naviagte("/login");
        setloged(false);
        return;
      }
      if (!res.emailVerified) {
        naviagte("/verify", { state: res });
        return;
      }
      setloged(true);
      setPartner(res);
    };
    checklogin();
  }, [naviagte]);
  if (logedin) {
    return (
      <>
        <div>
          <Header
            partner={partner}
            logedin={logedin}
            funclogin={setloged}
            step={state.step}
          />
          <Routes>
            <Route
              exact
              path="/"
              element={
                <PartnershipType partner={partner} dispatch={dispatch} />
              }
            />
            <Route
              exact
              path="/company"
              element={<CompanyDetails dispatch={dispatch} />}
            />
            <Route
              exact
              path="/agreement"
              element={<Agreement state={state} dispatch={dispatch} />}
            />
            <Route exact path="/submission" element={<Submision />} />
          </Routes>
          <Footer />
        </div>
      </>
    );
  }
};

export default PartnerDash;
