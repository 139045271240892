import axios from "axios";
import { PDFDocument, rgb } from "pdf-lib";
import React, { useEffect, useState } from "react";
import { GoDesktopDownload } from "react-icons/go";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { checkPartnerLogin } from "./apis/PartnersAPI";
import "./GenuineVerifier.css";
import logo from "./images/logo192.png";
import doubt from "./images/verifier_doubt.png";
import download from "./images/verifier_download.png";

const GenuineVerifierPAge = () => {
  const [pdfInfo, setPdfInfo] = useState([]);
  const naviagte = useNavigate();
  const location = useLocation();
  const state = location.state.partnerdetail;
  const [selectedFile, setSelectedFile] = useState(null);

  const upload = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      Swal.fire({
        icon: "error",
        title: "Oops... Something Went Wrong.",
        text: "File is not selected Duccefully..",
      });
      return null;
    }
    const formData = new FormData();
    formData.append("pdf", selectedFile);
    formData.append("partner", location.state._id);
    try {
      const config = {
        method: "post",
        url:
          process.env.REACT_APP_API_API_URL +
          "/nirvanadev/api/v1/partner/detail/addsumbitpdf",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      };
      const res = await axios(config);
      if (!res.status === 200) {
        throw new Error(res.error);
      }
      Swal.fire({
        icon: "success",
        title:
          "Successfully Uploaded... Please Wait for 24 Hours, We will get to you Soon.",
        text: res.data.msg,
      });
    } catch (error) {
      if (error.response.data.msg)
        Swal.fire({
          icon: "error",
          title: "Oops... Please try After Sometime.",
          text: error.response.data.msg,
        });
    }
  };
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  useEffect(() => {
    const checklogin = async () => {
      const res = await checkPartnerLogin();
      if (!res) {
        naviagte("/login");
        return;
      }
      if (!res.emailVerified) {
        naviagte("/verify", { state: res });
        return;
      }
      if (res.genuine) {
        naviagte("/home");
        return;
      }
    };
    checklogin();
  }, [naviagte]);

  useEffect(() => {
    const generatePdf = async () => {
      var document = await PDFDocument.create();
      const pdfcommonread = await fetch(
        process.env.REACT_APP_API_API_URL +
          "/nirvana/api/pic/partner/pdf/static/Compliance.pdf"
      ).then((res) => res.arrayBuffer());
      var seconddoc = await PDFDocument.load(pdfcommonread);

      const secondDonorPage = await document.copyPages(
        seconddoc,
        seconddoc.getPageIndices()
      );
      const fontsize = 12;
      const pageAdd = document.addPage();
      const logopngread = await fetch(
        "/static/media/logo192.fc5c897e483773969c14.png"
      ).then((res) => res.arrayBuffer());
      const pngImage = await document.embedPng(logopngread);
      pageAdd.drawLine({
        start: { x: 25, y: 25 },
        end: { x: 25, y: 817 },
        thickness: 1.5,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pageAdd.drawLine({
        start: { x: 570, y: 25 },
        end: { x: 570, y: 817 },
        thickness: 1.5,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pageAdd.drawLine({
        start: { x: 25, y: 817 },
        end: { x: 570, y: 817 },
        thickness: 1.5,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pageAdd.drawLine({
        start: { x: 25, y: 25 },
        end: { x: 570, y: 25 },
        thickness: 1.5,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pageAdd.drawImage(pngImage, {
        x: pageAdd.getWidth() / 2 - 30,
        y: 700,
        width: 60,
        height: 60,
      });

      pageAdd.drawText("Niladvantage Technologies Pvt. Ltd.", {
        x: 150,
        y: 680,
        size: fontsize + 6,
        color: rgb(0, 0.53, 0.71),
      });
      pageAdd.drawText("NirvanaMeet Partnership Agreement", {
        x: 180,
        y: 660,
        color: rgb(0, 0.53, 0.71),
        size: fontsize + 2,
      });
      pageAdd.drawText("PARTNER INFO", {
        x: 230,
        y: 525,
        color: rgb(0, 0.53, 0.71),
        size: fontsize + 4,
      });
      pageAdd.drawText(
        "Partner ID :            " + state.partnershipType.partner,
        {
          x: 140,
          y: 500,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        "Partner Name :      " +
          state.contactInformation.authorizedSignatoryFirstName +
          " " +
          state.contactInformation.authorizedSignatoryLastName,
        {
          x: 140,
          y: 480,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        "Partner Email :       " + state.contactInformation.buisnessEmail,
        {
          x: 140,
          y: 460,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        "Partner Phone :      " + state.contactInformation.phone,
        {
          x: 140,
          y: 440,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText("Partner Type :        " + state.partnershipType.role, {
        x: 140,
        y: 420,
        size: fontsize + 2,
      });
      pageAdd.drawText("PARTNER COMPANY INFO", {
        x: 190,
        y: 380,
        color: rgb(0, 0.53, 0.71),
        size: fontsize + 4,
      });
      pageAdd.drawText(
        "Company Name :                " + state.companyDetails.fullCompanyName,
        {
          x: 120,
          y: 360,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        "Company Web Address :    " + state.companyDetails.companyWebAddress,
        {
          x: 120,
          y: 340,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        "Company Email :                " +
          state.companyDetails.companyEmailDomain,
        {
          x: 120,
          y: 320,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        "Company Phone :               " + state.companyDetails.companyPhone,
        {
          x: 120,
          y: 300,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        "Company Description :       " +
          state.companyDetails.companyDescription,
        {
          x: 120,
          y: 280,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        "Company Location :            " +
          state.companyDetails.city +
          "-" +
          state.companyDetails.state +
          "-" +
          state.companyDetails.pincode,
        {
          x: 120,
          y: 260,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        state.partnershipType.role.toUpperCase() + " PARTNER AGGREMENT",
        {
          x: 170,
          y: 200,
          color: rgb(0, 0.53, 0.71),
          size: fontsize + 4,
        }
      );
      const agreement = state.aggrement.content.split(",");
      let size = 200;
      agreement.forEach((text) => {
        size = size - 20;
        pageAdd.drawText(text, {
          x: 570 / 2 - text.length * 2.6,
          y: size,
          size: fontsize + 2,
        });
      });

      secondDonorPage.forEach((page) => document.addPage(page));

      const pdfBytes = await document.save();
      const bytes = new Uint8Array(pdfBytes);
      const blob = new Blob([bytes], { type: "application/pdf" });
      const docUrl = URL.createObjectURL(blob);
      setPdfInfo(docUrl);
    };

    generatePdf();
  }, [state]);

  return (
    <div className="genuine-container">
      <div className="circle"></div>
      <div className="center">
        <div className="top">
          <div className="brand">
            <div className="logo">
              <img src={logo} alt="" />
              <h2>NirvanaMeet Partner Portal</h2>
            </div>
            <div className="profile">
              <p>
                Welcome {location.state.firstname} {location.state.lastname}
              </p>
              <NavLink className={"home-btn"} to="/">
                Home Page
              </NavLink>
            </div>
          </div>
          <div className="divider-hr"></div>
          <div className="t-center">
            <div className="t-left">
              <img src={doubt} alt="" />
            </div>
            <div className="t-right">
              <h1>Hmm! 😢🤷‍♂️ Account is Not Verified Yet by Our Team</h1>
              <div className="t-body">
                <p>Get Yourself Verified (follow the steps bellow) :</p>
                <div className="divider-hr"></div>
                <ol type="1">
                  <li>
                    Download Your Partnership Form, from below and read, answer
                    the questions & sign the Document Manualy.
                  </li>

                  <li>
                    Scan and Make the High Quality PDF File of the Singned Hard
                    Copy.
                  </li>
                  <li>
                    Choose the File from below Upload Field. Then Click on
                    Upload Button.
                  </li>
                  <li>
                    That's it, Your Work id Done, our team will check & verify
                    your request and if its all good, you will Recieve a mail
                    and you will be able to access your account. (note :if
                    Required our Team may call you.)
                  </li>
                </ol>
              </div>
              <form className="bottom" onSubmit={upload}>
                <label>Upload Partnership Pdf :</label>
                <input
                  type={"file"}
                  required
                  id="contact"
                  onChange={handleFileSelect}
                />
                <button type="submit" className="button">
                  Upload
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="down">
          <div className="left">
            <iframe src={pdfInfo} height={500} title="pdfFile" />
          </div>
          <div className="right">
            <div className="top">
              <img src={download} alt="" />
            </div>
            <div className="bottom">
              <p>
                This is your Filled form for Partnership, Download this, read
                and sign and then Upload here Back.
              </p>
              <a href={pdfInfo} download className="download-btn">
                <GoDesktopDownload /> Download Application
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenuineVerifierPAge;
