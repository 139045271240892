import { PDFDocument, rgb } from "pdf-lib";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Submission.css";
import { GoDesktopDownload } from "react-icons/go";
import { ImSpinner10 } from "react-icons/im";

const Submision = () => {
  const locationState = useLocation();
  const navigate = useNavigate();
  const state = locationState.state;
  const [pdfInfo, setPdfInfo] = useState([]);

  const submitApplication = () => {
    navigate("/home");
  };
  //PDF Generator

  //useEffect to Call Pdf for the First Time
  useEffect(() => {
    const generatePdf = async () => {
      var document = await PDFDocument.create();
      const pdfcommonread = await fetch(
        process.env.REACT_APP_API_API_URL +
          "/nirvana/api/pic/partner/pdf/static/Compliance.pdf"
      ).then((res) => res.arrayBuffer());
      var seconddoc = await PDFDocument.load(pdfcommonread);

      const secondDonorPage = await document.copyPages(
        seconddoc,
        seconddoc.getPageIndices()
      );
      const fontsize = 12;
      const pageAdd = document.addPage();
      const logopngread = await fetch(
        "/static/media/logo192.fc5c897e483773969c14.png"
      ).then((res) => res.arrayBuffer());
      const pngImage = await document.embedPng(logopngread);
      pageAdd.drawLine({
        start: { x: 25, y: 25 },
        end: { x: 25, y: 817 },
        thickness: 1.5,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pageAdd.drawLine({
        start: { x: 570, y: 25 },
        end: { x: 570, y: 817 },
        thickness: 1.5,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pageAdd.drawLine({
        start: { x: 25, y: 817 },
        end: { x: 570, y: 817 },
        thickness: 1.5,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pageAdd.drawLine({
        start: { x: 25, y: 25 },
        end: { x: 570, y: 25 },
        thickness: 1.5,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pageAdd.drawImage(pngImage, {
        x: pageAdd.getWidth() / 2 - 30,
        y: 700,
        width: 60,
        height: 60,
      });

      pageAdd.drawText("Niladvantage Technologies Pvt. Ltd.", {
        x: 150,
        y: 680,
        size: fontsize + 6,
        color: rgb(0, 0.53, 0.71),
      });
      pageAdd.drawText("NirvanaMeet Partnership Agreement", {
        x: 180,
        y: 660,
        color: rgb(0, 0.53, 0.71),
        size: fontsize + 2,
      });
      pageAdd.drawText("PARTNER INFO", {
        x: 230,
        y: 525,
        color: rgb(0, 0.53, 0.71),
        size: fontsize + 4,
      });
      pageAdd.drawText(
        "Partner ID :            " + state.partnershipType.partner,
        {
          x: 140,
          y: 500,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        "Partner Name :      " +
          state.contactInformation.authorizedSignatoryFirstName +
          " " +
          state.contactInformation.authorizedSignatoryLastName,
        {
          x: 140,
          y: 480,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        "Partner Email :       " + state.contactInformation.buisnessEmail,
        {
          x: 140,
          y: 460,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        "Partner Phone :      " + state.contactInformation.phone,
        {
          x: 140,
          y: 440,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText("Partner Type :        " + state.partnershipType.role, {
        x: 140,
        y: 420,
        size: fontsize + 2,
      });
      pageAdd.drawText("PARTNER COMPANY INFO", {
        x: 190,
        y: 380,
        color: rgb(0, 0.53, 0.71),
        size: fontsize + 4,
      });
      pageAdd.drawText(
        "Company Name :                " + state.companyDetails.fullCompanyName,
        {
          x: 120,
          y: 360,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        "Company Web Address :    " + state.companyDetails.companyWebAddress,
        {
          x: 120,
          y: 340,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        "Company Email :                " +
          state.companyDetails.companyEmailDomain,
        {
          x: 120,
          y: 320,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        "Company Phone :               " + state.companyDetails.companyPhone,
        {
          x: 120,
          y: 300,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        "Company Description :       " +
          state.companyDetails.companyDescription,
        {
          x: 120,
          y: 280,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        "Company Location :            " +
          state.companyDetails.city +
          "-" +
          state.companyDetails.state +
          "-" +
          state.companyDetails.pincode,
        {
          x: 120,
          y: 260,
          size: fontsize + 2,
        }
      );
      pageAdd.drawText(
        state.partnershipType.role.toUpperCase() + " PARTNER AGGREMENT",
        {
          x: 170,
          y: 200,
          color: rgb(0, 0.53, 0.71),
          size: fontsize + 4,
        }
      );
      const agreement = state.aggrement.content.split(",");
      let size = 200;
      agreement.forEach((text) => {
        size = size - 20;
        pageAdd.drawText(text, {
          x: 570 / 2 - text.length * 2.6,
          y: size,
          size: fontsize + 2,
        });
      });

      secondDonorPage.forEach((page) => document.addPage(page));

      const pdfBytes = await document.save();
      const bytes = new Uint8Array(pdfBytes);
      const blob = new Blob([bytes], { type: "application/pdf" });
      const docUrl = URL.createObjectURL(blob);
      setPdfInfo(docUrl);
    };

    generatePdf();
  }, [state]);

  return (
    <>
      <div className="submission-container">
        {pdfInfo.length > 0 ? (
          <>
            <h2>
              <b>Submission Process</b>
            </h2>

            <div className="center">
              <iframe
                src={pdfInfo}
                width="600"
                height="300"
                title="pdfContent"
                id="pdfContent"
              ></iframe>

              <div className="right">
                <p>
                  <span>Thank You For Considering NirvanaMeet</span>
                  <br /> <br />
                  Please Follow the Steps to Get Your Partner account Activated
                  :-
                </p>
                <ul>
                  <li>
                    Download The Application
                    <a href={pdfInfo} download>
                      &nbsp;Download
                    </a>
                  </li>
                  <li>Fill The Questions in the Form.</li>
                  <li>Add Your Signature With Date and Company Stamp.</li>
                  <li>
                    Mail The PDF to
                    <a href="mailto:info@niladvantage.com">
                      &nbsp;info@niladvantage.com
                    </a>
                  </li>
                </ul>
                <br />
                <p>
                  After Submission Within 24 hours, You Will be Updated with
                  Partnership Status
                </p>
                <a href={pdfInfo} download className="download-btn">
                  <GoDesktopDownload /> Download Application
                </a>
              </div>
            </div>

            <div className="submit-application ">
              <button onClick={submitApplication} className="button">
                Dashboard
              </button>
            </div>
          </>
        ) : (
          <>
            <p style={{ marginBottom: "2rem" }}>
              Generating Application Form,Please Wait.....
            </p>
            <ImSpinner10 className="spinner" />
          </>
        )}
      </div>
    </>
  );
};

export default Submision;
