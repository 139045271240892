import React, { useEffect, useState } from "react";
import "./profilePage.css";
import Foot from "../screen/home/foot/foot";
import Head from "../screen/home/head/head";
import profileImage from "../images/profile_page.png";
import { checkPartnerLogin } from "../apis/PartnersAPI";
import Swal from "sweetalert2";
import { ImSpinner10 } from "react-icons/im";

function ProfilePage() {
  const [data, setData] = useState({});
  const [logedin, setloged] = useState(false);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const getInfo = async () => {
      setLoader(true);
      const res = await checkPartnerLogin();
      if (!res) {
        await Swal.fire({
          icon: "error",
          title: "Oops... Something Went Wrong",
          text: "Please try After Sometime.",
        });
        setloged(false);
        return;
      }
      setLoader(false);
      setData(res);
      setloged(true);
    };
    getInfo();
  }, []);
  if (logedin) {
    return (
      <>
        <Head
          partner={data}
          funclogin={setloged}
          login={logedin}
          hide={logedin ? true : false}
        />
        <div className="registers center">
          {loader ? (
            <ImSpinner10 id="loadform" className="spinner" />
          ) : (
            <>
              <p
                style={{
                  color: "red",
                  marginBottom: "-2rem",
                  marginTop: "2rem",
                  fontSize: "larger",
                }}
              >
                *To Change any Detail please raise a support Ticket.
              </p>
              <div className="profile-page">
                <div className="left">
                  <img src={profileImage} alt="" height="250" />
                </div>
                <div className="right">
                  <img
                    src={process.env.REACT_APP_API_API_URL + data.photoUrl}
                    alt=""
                  />
                  <div className="profile-body">
                    <span>Personal Details</span>
                    <hr />
                    <div className="personal">
                      <p>
                        Name: {data.firstname} {data.lastname}
                      </p>
                      <p>Email: {data.email}</p>
                      <p>Phone: {data.phone}</p>
                    </div>
                    <span>Company Details</span>
                    <hr />
                    <div className="company">
                      <p>
                        Company Name: &nbsp;
                        {data.partnerdetail.companyDetails.fullCompanyName}
                      </p>
                      <p>GSTIN: {data.partnerdetail.companyDetails.gstin}</p>
                      <p>
                        Address: {data.partnerdetail.companyDetails.address} ,
                        {data.partnerdetail.companyDetails.city},
                        {data.partnerdetail.companyDetails.state}-
                        {data.partnerdetail.companyDetails.pincode},
                        {data.partnerdetail.companyDetails.country}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <Foot />
      </>
    );
  }
}
export default ProfilePage;
