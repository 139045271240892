import React, { useEffect, useState } from "react";
import "./ManageDeals.css";
import axios from "axios";

const ManageDeals = ({ partner }) => {
  const [alldeals, setAlldeals] = useState([]);
  useEffect(() => {
    const getAlldeals = async () => {
      try {
        const config = {
          method: "get",
          url:
            process.env.REACT_APP_API_API_URL +
            "/nirvanadev/api/v1/partner/deals/getpartnerdeals",
          withCredentials: true,
        };
        const res = await axios(config);
        if (!res.status === 200) {
          throw new Error(res.error);
        }
        setAlldeals(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAlldeals();
  }, []);
  const listDeals = alldeals.map((deal, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{deal.dealId}</td>
        <td>{deal.endcustomercompanydetails.companyname}</td>
        <td>{deal.endcustomercompanydetails.state}</td>
        <td>{deal.plandetails.plans[0].planrequested.name}</td>
        <td>{deal.plandetails.plans[0].no_license}</td>
        <td>₹ {deal.plandetails.plans[0].planrequested.price}</td>
        <td>
          ₹{" "}
          {deal.plandetails.plans[0].planrequested.price *
            deal.plandetails.plans[0].no_license}
        </td>
      </tr>
    );
  });
  return (
    <>
      <div className="mng-body">
        <h3>ALL Deals Resitered & Value</h3>
        <p>{partner.partnerdetail.companyDetails.fullCompanyName}</p>
        <div className="deal-center">
          <div className="deals">
            <table className="deal">
              <thead>
                <tr>
                  <th>SR.NO</th>
                  <th>Deal ID</th>
                  <th>Company Name</th>
                  <th>State</th>
                  <th>Plan</th>
                  <th>Quantity</th>
                  <th>Rate</th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>{listDeals}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageDeals;
