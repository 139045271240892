import React, { useState } from "react";
import "./verify.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Foot from "../home/foot/foot";
import Head from "../home/head/head";
import Swal from "sweetalert2";

function Verify() {
  const data = useLocation();
  // let phone = data.state.phone;
  let email = data.state.email;
  const navigate = useNavigate();
  const [verify, setVerify] = useState({
    phoneotp: "",
    emailotp: "",
  });
  let name, value;
  const handleChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setVerify({ ...verify, [name]: value });
  };
  //to hide and stop the number filed spiner and mouse effect

  // useEffect(() => {
  //   if (data.state.phoneVerified) {
  //     document.getElementById("email").classList.remove("email");
  //     document.getElementById("phone").classList.add("email");
  //   }
  // }, [data]);
  // const submitPhoneOtp = async () => {
  //   try {
  //     const phoneVerify = {
  //       method: "post",
  //       url: "/nirvanadev/api/v1/partner/phoneverifyotp",
  //       data: {
  //         phone: phone, //change with dynamic variable,
  //         otp: verify.phoneotp,
  //       },
  //       auth: {
  //
  // username: process.env.REACT_APP_API_USERNAME,
  // password: process.env.REACT_APP_API_PASSWORD,
  //       },
  //     };

  //     const resphone = await axios(phoneVerify);
  //     if (!resphone.status === 200) throw new Error(resphone.Error);
  //     Swal.fire({
  //       position: "center",
  //       icon: "success",
  //       title: "Phone OTP has been Verified.. ",
  //       showConfirmButton: false,
  //       timer: 1000,
  //     });
  //     if (!data.state.emailVerified) {
  //       document.getElementById("email").classList.remove("email");
  //       document.getElementById("phone").classList.add("email");
  //     } else navigate("/login");
  //   } catch (error) {
  //     if (error.response) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops... Status " + error.response.status,
  //         text: error.response.data.msg,
  //       });
  //     }
  //   }
  // };
  const submitEmailOtp = async () => {
    try {
      const emailVerify = {
        method: "post",
        url:
          process.env.REACT_APP_API_API_URL +
          "/nirvanadev/api/v1/partner/emailverifyotp",
        data: {
          email: email, //change with dynamic variable,
          otp: verify.emailotp,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      };
      const resemail = await axios(emailVerify);
      if (!resemail.status === 200) throw new Error(resemail.Error);

      Swal.fire({
        position: "center",
        icon: "success",
        title:
          "Email OTP has been Verified.. \n Account Has been Created Succefully",
        showConfirmButton: false,
        timer: 1500,
      });
      // if (!data.state.phoneVerified) {
      //   document.getElementById("phone").classList.remove("email");
      //   document.getElementById("email").classList.add("email");
      // } else
      navigate("/login");
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops... Status " + error.response.status,
          text: error.response.data.msg,
        });
      }
    }
  };

  // const sendPhoneOtp = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const phoneSend = {
  //       method: "post",
  //       url: "/nirvanadev/api/v1/partner/phonecreateotp",
  //       data: {
  //         phone: String(phone), //change with dynamic variable,
  //       },
  //       auth: {
  //
  // username: process.env.REACT_APP_API_USERNAME,
  // password: process.env.REACT_APP_API_PASSWORD,
  //       },
  //     };
  //     const resphone = await axios(phoneSend);
  //     if (!resphone.status === 200) throw new Error(resphone.Error);
  //     Swal.fire({
  //       position: "center",
  //       icon: "success",
  //       title: "OTP has been Sent yo Your Phone",
  //       showConfirmButton: false,
  //       timer: 1000,
  //     });
  //   } catch (error) {
  //     if (error.response) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops... Status " + error.response.status,
  //         text: error.response.data.msg,
  //       });
  //     }
  //   }
  // };

  const sendEmailOtp = async () => {
    try {
      const emailSend = {
        method: "post",
        url:
          process.env.REACT_APP_API_API_URL +
          "/nirvanadev/api/v1/partner/emailcreateotp",
        data: {
          email: email, //change with dynamic variable,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      };
      const resemail = await axios(emailSend);
      if (!resemail.status === 200) throw new Error(resemail.Error);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "OTP has been Sent to Your Email",
        showConfirmButton: false,
        timer: 1000,
      });
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops... Status " + error.response.status,
          text: error.response.data.msg,
        });
      }
    }
  };

  return (
    <div className="registers">
      <Head hide={false} home={true} />
      <div className="verify">
        {/* <div id="phone" className={"verify-body"}>
          <p>Please Enter Your Phone OTP</p>
          <p className="note">{phone}</p>
          <input
            type="text"
            name="phoneotp"
            autoComplete="off"
            value={verify.phoneotp}
            onChange={handleChange}
            required
          />
          <div className="buttons">
            <button onClick={sendPhoneOtp}>Send</button>
            <button onClick={submitPhoneOtp}>Verify</button>
          </div>
        </div> */}
        <div id="email" className="verify-body">
          <p>Please Enter Your Email OTP</p>
          <p className="note">{email}</p>
          <input
            type="number"
            id="emailotp"
            name="emailotp"
            autoComplete="off"
            value={verify.emailotp}
            onChange={handleChange}
            required
          />
          <div className="buttons">
            <button onClick={sendEmailOtp}>Send</button>
            <button onClick={submitEmailOtp}>Verify</button>
          </div>
        </div>
      </div>
      <Foot />
    </div>
  );
}

export default Verify;
