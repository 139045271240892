import React, { useEffect, useReducer, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./homepage.css";
import { useNavigate } from "react-router-dom";
import DealRegistrationHome from "./deal/dealRegistrationHome/dealHome";
import Header from "./header/Header";
import Foot from "../home/foot/foot";
import DealRegistration from "./deal/dealRegistration/deal";
import Next from "./deal/dealRegistration/next";
import Home from "./home/Home";
import { checkPartnerLogin } from "../../apis/PartnersAPI";
import Features from "./features/Features";
import Customer from "./support/Customer";
import HomeReducer from "./HomeReducer";
import ManageDeals from "./managedeals/ManageDeals";

const HomeInitalState = {
  endcustomercompanydetails: {},
  plandetails: {},
  ownerdetials: {},
  dealdetails: {},
};

function HomePage() {
  const naviagte = useNavigate();
  const [dealReg, setDealreg] = useReducer(HomeReducer, HomeInitalState);
  const [logedin, setloged] = useState(false);
  const [partner, setPartner] = useState({});

  useEffect(() => {
    const checklogin = async () => {
      const res = await checkPartnerLogin();
      if (!res) {
        naviagte("/login");
        setloged(false);
        return;
      }
      if (!res.emailVerified) {
        naviagte("/verify", { state: res });
        return;
      } else if (!res.havedetail) {
        naviagte("/partner");
        return;
      } else if (!res.genuine) {
        naviagte("/verifier", { state: res });
        return;
      }
      setloged(true);
      setPartner(res);
      setDealreg({ type: "ADD_DEALDETAIL_PART", payload: res._id });
    };
    checklogin();
  }, [naviagte]);
  if (logedin) {
    return (
      <>
        <Header partner={partner} logedin={logedin} funclogin={setloged} />
        <Routes>
          <Route exact path={"/"} element={<Home />} />
          <Route exact path={"/dealinfo"} element={<DealRegistrationHome />} />
          <Route
            exact
            path={"/dealregistration"}
            element={<DealRegistration dispatch={setDealreg} />}
          />
          <Route exact path={"/features"} element={<Features />} />
          <Route
            exact
            path={"/dealregistration/next"}
            element={<Next data={dealReg} dispatch={setDealreg} />}
          />
          <Route
            exact
            path={"/support"}
            element={<Customer user={partner} />}
          />
          <Route
            exact
            path={"/managedeals"}
            element={<ManageDeals partner={partner} />}
          />
        </Routes>
        <Foot />
      </>
    );
  }
}

export default HomePage;
