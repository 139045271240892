import React, { useState } from "react";
import "./partnershipType.css";

import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function PartnershipType({ dispatch, partner }) {
  const nav = useNavigate();
  const [check, setCheck] = useState(false);
  const [reseller, setReseller] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!check || !reseller) {
        Swal.fire({
          icon: "success",
          title: "Hmmm🤷‍♂️🤷‍♂️... Please Follow the Steps Partner 🙄",
          text: "Please check the Condition & We allow Reseller only for now,\n Please Register if You are Reseller",
        });
      } else {
        if (reseller) {
          const aggrement = {
            language: "English",
            content: document.getElementById("agreement").innerHTML,
          };
          await dispatch({ type: "ADD_AGGREMENT", payload: aggrement });
          const partnershipType = {
            role: "Reseller",
            partner: partner._id,
          };
          await dispatch({
            type: "ADD_PARTNERSHIP_TYPE",
            payload: partnershipType,
          });
        }
        dispatch({ type: "ADD_NEXT_STEP", payload: 1 });
        nav("/partner/company");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form className="prtnersection" onSubmit={handleSubmit}>
      <div className="my-checkbox">
        <input
          type="checkbox"
          required
          onClick={(e) => {
            if (e.target.checked) setCheck(true);
            else setCheck(false);
          }}
        />
        <p>
          I certify that, I am an <b>authorized signatory</b> for my company
        </p>
      </div>

      <div className="my-checkbox">
        <input
          type="checkbox"
          required
          onClick={(e) => {
            if (e.target.checked) setReseller(true);
            else setReseller(false);
          }}
        />

        <p>
          <b>Reseller Partner : </b>
          <span id="agreement">
            Our company will resell Nirvanameet VC Solution, We have an existing
            and viable account relationship with,Niladvantage Authorized
            Distributor and meets,Niladvantage minimum eligibility requirements,
            to be a Niladvantage Registered Indirect Partner.
          </span>
        </p>
      </div>
      <div className="my-button">
        <input className="button" type="submit" value="Continue" />
        <Link className="button" to="/">
          Cancel
        </Link>
      </div>
    </form>
  );
}

export default PartnershipType;
