import React, { useState } from "react";
import "./register.css";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import regisImg from "../../images/hero-img.png";
import Head from "../home/head/head";
import Foot from "../home/foot/foot";
import Swal from "sweetalert2";

function Register({ setData }) {
  const nav = useNavigate();
  let [userRegistration, setuserRegistration] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    countrycode: "",
  });
  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setuserRegistration({
      ...userRegistration,
      [name]: value,
      countrycode: +91,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let {
      firstname,
      lastname,
      email,
      phone,
      password,
      confirmPassword,
      countrycode,
    } = userRegistration;
    try {
      await axios.post(
        process.env.REACT_APP_API_API_URL + "/nirvanadev/api/v1/partner/create",
        {
          firstname,
          lastname,
          email,
          phone,
          password,
          confirmPassword,
          countrycode,
        },
        {
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Successfully... Registered ",
        text: "Thanks You and NirvanaMeet Welcomes you",
      });
      const info = {
        phone: userRegistration.phone,
        email: userRegistration.email,
      };

      nav("/verify", { state: info });
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops... Status " + error.response.status,
          text: error.response.data.msg,
        });
      }
    }
  };

  return (
    <>
      <Head hide={false} home={true} />
      <div className="registers">
        <div class="login-reg-panel">
          <div class="login-info-box">
            <img src={regisImg} alt="" />
            <h2>Already, Have an account?</h2>
            <NavLink className="button" to="/login">
              Login Here
            </NavLink>
          </div>
          <form method="POST" class="white-panel" onSubmit={handleSubmit}>
            <div class="register-show">
              <h2>REGISTER</h2>
              <div className="form-control">
                <label for="name">
                  <b>First Name</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter First Name"
                  name="firstname"
                  value={userRegistration.firstname}
                  onChange={handleInputChange}
                  autoComplete="false"
                  required
                />
              </div>
              <div className="form-control">
                <label for="name">
                  <b>Last Name</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Last Name"
                  name="lastname"
                  value={userRegistration.lastname}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-control">
                <label for="email">
                  <b>Email</b>
                </label>
                <input
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  value={userRegistration.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-control">
                <label for="number">
                  <b>Phone Number</b>
                </label>
                <input
                  type="number"
                  placeholder="Enter your number"
                  name="phone"
                  value={userRegistration.number}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label for="number">
                  <b>Country Code</b>
                </label>
                <select
                  id="country"
                  name="countrycode"
                  value={userRegistration.code}
                  onChange={handleInputChange}
                  required
                >
                  <option>+91</option>
                </select>
              </div>
              <div className="form-control">
                <label for="password">
                  <b>Password</b>
                </label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  name="password"
                  id="psw"
                  value={userRegistration.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-control">
                <label for="confirmPassword">
                  <b>Confirm Password</b>
                </label>
                <input
                  type="password"
                  placeholder="confirm Password"
                  name="confirmPassword"
                  id="psw-repeat"
                  value={userRegistration.confirmPassword}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <p>
                By creating an account you agree to our
                <NavLink to="/privacy">Terms & Privacy</NavLink>.
              </p>
              <button type="submit">Register</button>
            </div>
          </form>
        </div>
      </div>
      <Foot />
    </>
  );
}

export default Register;
