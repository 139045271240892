import React from "react";
import "./foot.css";
import { FaEnvelopeOpenText, FaPhoneAlt } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { NavLink } from "react-router-dom";

function Foot() {
  return (
    <>
      <div className="main-foot">
        <div className="foot1">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/privacy">Privacy</NavLink>
          <NavLink to="/feedback">Feedback</NavLink>
        </div>
        <div className="foot2">
          <div className="foot2_1">
            <p>Contact Us:</p>
            <a href="mailto:info@niladvantage.com">
              <FaEnvelopeOpenText /> info@niladvantage.com
            </a>
            <a href="tel:+918884950768">
              <FaPhoneAlt /> +91-8884950768
            </a>
          </div>
          <div className="foot2_1">
            <p>Company Address:</p>
            <p>
              <GoLocation /> B109, CVK Meenakshi Elegance
            </p>
            <p>MLA Layout, Kalena Agharara</p>
            <p>Banerghatta Road 560076</p>
            <p>Banglore Karnataka</p>
          </div>
        </div>

        <div className="foot">
          <p className="p2">powered by</p>
          <p>Niladvantage Technologies Pvt Ltd</p>
        </div>
        <h5>@ Copyright 2022 at NirvanaMeet</h5>
      </div>
    </>
  );
}

export default Foot;
