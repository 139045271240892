import axios from "axios";
import React, { useEffect, useState } from "react";
import { ImSpinner10 } from "react-icons/im";
import { MdOutlineResetTv } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Foot from "../home/foot/foot";
import Head from "../home/head/head";
import "./ResetPassword.css";

const ResetPassword = () => {
  const [errorpassword, setErrorpassword] = useState(false);
  const [otpsent, setOtpsent] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState({
    email: "",
    password: "",
    otp: "",
    cnfpass: "",
  });

  useEffect(() => {
    if (detail.cnfpass !== detail.password) setErrorpassword(true);
    else setErrorpassword(false);
  }, [errorpassword, detail]);

  const handelInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDetail({ ...detail, [name]: value });
  };

  const sendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const config = {
        method: "post",
        url:
          process.env.REACT_APP_API_API_URL +
          "/nirvanadev/api/v1/partner/resetpasswordotp",
        data: detail,
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      };
      const res = await axios(config);
      if (!res.status === 200) throw new Error(res.error);

      setOtpsent(true);
      await Swal.fire({
        icon: "success",
        title: "OTP Sent to Your Email " + detail.email,
        text: "Please Check Your Mail",
      });
      setLoading(false);
    } catch (error) {
      setOtpsent(false);
      if (!error.response.data) {
        Swal.fire({
          icon: "error",
          title: "Oops .... 🤷‍♂️Something went Wrong",
          text: "Please Try after Sometime..",
        });
        setLoading(false);
        return;
      }
      Swal.fire({
        icon: "error",
        title: "Oops .... 🤷‍♂️Something went Wrong",
        text: detail.email + " " + error.response.data.msg,
      });
      setLoading(false);
    }
  };
  const changePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const config = {
        method: "post",
        url: "/nirvanadev/api/v1/partner/resetpasswordconfirm",
        data: detail,
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      };
      const res = await axios(config);
      if (!res.status === 200) throw new Error(res.error);

      setOtpsent(true);
      await Swal.fire({
        icon: "success",
        title: "Successfull Password Reset...! ",
        text: ` Account with Email :${detail.email}\nPassword is has been Changed..`,
      });
      setLoading(false);
      navigate("/login");
    } catch (error) {
      setOtpsent(false);
      if (!error.response.data) {
        Swal.fire({
          icon: "error",
          title: "Oops .... 🤷‍♂️Something went Wrong",
          text: "Please Try after Sometime..",
        });
        setLoading(false);
        return;
      }
      if (error.response.data.msg === "invalid otp") setOtpsent(true);
      Swal.fire({
        icon: "error",
        title: "Oops .... 🤷‍♂️Something went Wrong",
        text: error.response.data.msg,
      });
      setLoading(false);
    }
  };
  return (
    <>
      <Head hide={false} home={true} />
      <div className="registers reset-psw">
        <div className="head">
          <h3>
            <MdOutlineResetTv />
            Reset Your Account Passwrord
          </h3>
          <p>We are always a Advantage to our Customers...</p>
        </div>
        <div className="center">
          <div className="reset-container">
            {loading ? (
              <>
                <p style={{ marginBottom: "2rem" }}>
                  Checking Your Request, Please Wait.....
                </p>
                <ImSpinner10 className="spinner" />
              </>
            ) : (
              <>
                {!otpsent ? (
                  <form id="emailOtp" onSubmit={sendOtp}>
                    <label>Email :</label>
                    <input
                      type={"email"}
                      name="email"
                      required
                      onChange={handelInput}
                    />
                    <button type="submit">Send OTP</button>
                  </form>
                ) : (
                  <>
                    <form id="resetPass" onSubmit={changePassword}>
                      <label>OTP :</label>
                      <input
                        type="number"
                        name="otp"
                        required
                        onChange={handelInput}
                      />
                      <label>New Passwrord :</label>
                      <input
                        type={"password"}
                        id="password"
                        name="password"
                        required
                        onChange={handelInput}
                      />
                      <label>Confirm Passwrord :</label>
                      <input
                        type={"password"}
                        name="cnfpass"
                        id="cnfpass"
                        required
                        onChange={handelInput}
                      />
                      <button type="submit">Reset Password</button>
                    </form>
                    {errorpassword ? (
                      <div id="Error-password">
                        *Password does't Match with Confirm Password...
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Foot />
    </>
  );
};

export default ResetPassword;
