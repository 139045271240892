import React, { useEffect, useState } from "react";
import { ImSpinner10 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { createTicket } from "../apis/ContactFormAPI";
import { checkPartnerLogin } from "../apis/PartnersAPI";
import Foot from "../screen/home/foot/foot";
import Head from "../screen/home/head/head";
import "./Contact_us.css";
import {
  FaEnvelope,
  FaEnvelopeOpenText,
  FaNewspaper,
  FaPhone,
  FaUserShield,
} from "react-icons/fa";
import { FcOnlineSupport } from "react-icons/fc";

const Contactus = () => {
  const navigate = useNavigate();
  const [logedin, setloged] = useState(false);
  const [user, setUser] = useState([]);
  const [ticketLoad, setTicketLoad] = useState(false);
  //React Hook
  const [query, setQuery] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    type: "",
    ownerid: "",
  });

  //to hide and stop the number filed spiner and mouse effect
  useEffect(() => {
    const getpartner = async () => {
      const res = await checkPartnerLogin();
      if (!res) {
        navigate("/login");
        setloged(false);
        return;
      }
      if (!res.phoneVerified && !res.emailVerified) {
        navigate("/verify", { state: res });
        return;
      }
      setloged(true);
      setUser(res);
    };
    var inputTypeNumbers = document.querySelectorAll("input[type=number]");
    for (var a = 0; a < inputTypeNumbers.length; a++) {
      inputTypeNumbers[a].onwheel = function (event) {
        event.target.blur();
      };
    }
    getpartner();
  }, [navigate]);

  //Contact form Field Value Change Event
  const changeFieldEvent = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    setQuery({
      ...query,
      [fieldName]: fieldValue,
      name: user.firstname + " " + user.lastname,
      phone: user.phone,
      email: user.email,
      type: user.type,
      ownerid: user._id.valueOf(),
    });
  };

  //fuction on contact form submit
  const onContactFormSubmit = async (event) => {
    setTicketLoad(true);
    event.preventDefault();
    const res = await createTicket(query);
    if (res.success === false) {
      Swal.fire({
        icon: "error",
        title: "Oops... Failed to Create Ticket\nPlease try After Sometime.",
        text: res.msg,
      });
      setTicketLoad(false);
      return;
    } else {
      Swal.fire({
        icon: "success",
        title: res.msg + " and your ticket no. is #" + res.data.ticketno,
        text: "We Will Soon get in touch with you.",
      });
    }
    setQuery({ ...user, subject: "", message: "" });
    setTicketLoad(false);
  };
  if (logedin) {
    return (
      <>
        <Head
          partner={user}
          funclogin={setloged}
          login={logedin}
          hide={logedin ? true : false}
        />
        <div className=" Customer-Container">
          <div className="head">
            <h3>
              <FcOnlineSupport /> Contact Us
            </h3>
            <p>Always a Advantage to our Customers...</p>
          </div>
          <div className="contact-form-container">
            <div className="contact-form">
              {ticketLoad ? (
                <ImSpinner10 id="loadform" className="spinner" />
              ) : (
                <form
                  className="form"
                  id="contact-form"
                  method="POST"
                  encType="multipart/form-data"
                  onSubmit={onContactFormSubmit}
                >
                  <div className="formgroup-container">
                    <div className="form-group">
                      <span className="icon left-icon">
                        <FaUserShield size={"20px"} />
                      </span>
                      <input
                        placeholder="Name"
                        type="text"
                        className="input-email"
                        required
                        readOnly
                        defaultValue={user.firstname + " " + user.lastname}
                        name="name"
                      />
                      <label htmlFor="name">Name</label>
                    </div>
                    <div className="form-group">
                      <span className="icon left-icon">
                        <FaEnvelope size={"20px"} />
                      </span>
                      <input
                        placeholder="Email"
                        type="email"
                        className="input-email"
                        required
                        readOnly
                        defaultValue={user.email}
                        name="email"
                      />
                      <label htmlFor="email">Email</label>
                    </div>
                  </div>
                  <div className="formgroup-container">
                    <div className="form-group">
                      <span className="icon left-icon">
                        <FaPhone size={"20px"} />
                      </span>
                      <input
                        placeholder="Phone"
                        type="number"
                        readOnly
                        className="input-email"
                        name="phone"
                        defaultValue={user.phone}
                        required
                      />
                      <label htmlFor="phone">Phone</label>
                    </div>
                    <div className="form-group">
                      <span className="icon left-icon">
                        <FaNewspaper size={"20px"} />
                      </span>
                      <input
                        placeholder="Subject"
                        type="text"
                        className="input-email"
                        name="subject"
                        required
                        value={query.subject}
                        onChange={changeFieldEvent}
                      />
                      <label htmlFor="subject">Subject</label>
                    </div>
                  </div>
                  <div className="formgroup-container textarea">
                    <div className="form-group">
                      <span className="icon left-icon">
                        <FaEnvelopeOpenText size={"20px"} />
                      </span>
                      <textarea
                        placeholder="Query/ Message"
                        className="input-email"
                        name="message"
                        required
                        value={query.message}
                        onChange={changeFieldEvent}
                      ></textarea>
                      <label htmlFor="query-message">Query/ Message</label>
                    </div>
                  </div>
                  <div className="formgroup-container">
                    <button type="submit" className="form-butn">
                      <i className="fa-solid fa-paper-plane"></i>&nbsp;Send
                      Query
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
        <Foot />
      </>
    );
  }
};
export default Contactus;
