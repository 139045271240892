import React, { useState } from "react";
import "./feedback.css";
import Head from "../screen/home/head/head";
import Foot from "../screen/home/foot/foot";
import axios from "axios";
import Swal from "sweetalert2";

function Feedback() {
  const [feedback, setFeedback] = useState({
    name: "",
    email: "",
    description: "",
    subject: "Feedback from Partner Portal",
    type: "general",
  });
  const fieldHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFeedback({ ...feedback, [name]: value });
  };

  const submitFeedback = async (e) => {
    e.preventDefault();
    try {
      const config = {
        method: "post",
        url:
          process.env.REACT_APP_API_API_URL +
          "/nirvanadev/api/v1/feedback/create",
        data: feedback,
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      };
      const res = await axios(config);
      if (!res.status === 200) {
        throw new Error(res.error);
      }
      Swal.fire({
        icon: "success",
        title: "Successfully Submited... Thank You for your Feedback.",
        text: "We will always be a Advantage to our Customers",
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops... Please try After Sometime.",
        text: error.response.data.msg,
      });
    }
  };

  return (
    <>
      <Head hide={false} home={true} />
      <div className="registers center">
        <form className="feedback-page" onSubmit={submitFeedback}>
          <div className="feedback-body">
            <h1>Your Feedback</h1>
            <p>We would like your feedback to improve our website</p>
            <hr />
          </div>
          <div className="feedback-body">
            <label>Name: </label>
            <input
              required
              type="text"
              name="name"
              value={feedback.name}
              onChange={fieldHandler}
            />
          </div>

          <div className="feedback-body">
            <label>Email: </label>
            <input
              required
              name="email"
              type="text"
              value={feedback.email}
              onChange={fieldHandler}
            />
          </div>
          <div className="feedback-body">
            <p>Please give your valuable feedback below:</p>
            <textarea
              name="description"
              value={feedback.description}
              required
              onChange={fieldHandler}
            ></textarea>
          </div>
          <button type={"submit"}>Send</button>
        </form>
      </div>
      <Foot />
    </>
  );
}

export default Feedback;
