import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { updatePartnerDetails } from "../../../../apis/PartnersAPI";
import "./aggrement.css";

function Agreement({ state, dispatch }) {
  const navigate = useNavigate();
  //Sumit Application Function
  const submitApplication = async () => {
    const res = await updatePartnerDetails(state);
    if (!res) {
      Swal.fire({
        title: "<strong>Something Went Wrong</strong>",
        icon: "info",
        html: "<li>You May Have Already Submitted the Application</li><li> Please Try After Sometime</li><li> Please Contact us After you Mailed the Pdf With Signature. to info@niladvantage.com</li>",
        showCloseButton: true,
        confirmButtonText: "Ok",
      });

      navigate("/home");
      return;
    }
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Your Application is Submited With us.",
      showConfirmButton: false,
      timer: 1500,
    });

    navigate("/partner/submission", { state: state });
  };

  const submitAgreement = async (e) => {
    e.preventDefault();
    await dispatch({ type: "ADD_NEXT_STEP", payload: 3 });
    submitApplication();
  };

  return (
    <form className="agre-section" onSubmit={submitAgreement}>
      <div className="agre-contain">
        <h2>Partner Agreeement</h2>
      </div>
      <div className="agre-contain">
        <p>
          Read and accept Nirvanameet Indirect Partner Agreement. Nirvanameet
          may perform additional research to confirm the accuracy of infromation
          submitted and eligibility for partnership.
          <br />
          This agreement must be renewed anually. Renewing partners are advised
          to review the latest agreement, as{" "}
          <Link to="/privacy">terms and conditions</Link> may have changed.
        </p>
      </div>
      <div className="agre-contain ">
        <input className="check" type="checkbox" required />
        <Link to="/terms and condition">
          Click here to accept the agreement
        </Link>
      </div>
      <div className="agre-contain ">
        <button className="button" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
}

export default Agreement;
