import React, { useEffect, useState } from "react";
import "./home.css";
import image1 from "../../images/image1.png";
import image2 from "../../images/image2.png";
import image3 from "../../images/image3.png";
import image4 from "../../images/image4.png";
import image5 from "../../images/image5.png";
import Head from "../home/head/head";
import Foot from "./foot/foot";
import { checkPartnerLogin } from "../../apis/PartnersAPI";
import { NavLink } from "react-router-dom";

function Home() {
  const [haspartner, setHaspartner] = useState([]);
  const [login, setLogin] = useState(false);
  const checkPartner = async () => {
    const res = await checkPartnerLogin();
    if (!res) {
      setLogin(false);
      return;
    }
    setHaspartner(res);
    setLogin(true);
  };
  useEffect(() => {
    checkPartner();
  }, []);

  return (
    <div className="home">
      <Head
        partner={haspartner}
        funclogin={setLogin}
        login={login}
        hide={login ? true : false}
      />

      <div className="homes">
        <div className="left">
          <img src={image1} alt="" />
        </div>
        <div className="right">
          <h2>Welcome to Nirvanameet Partner Portal</h2>
          <p>
            Your single, global gateway to the Video Conferencing tools and
            information needed to grow your business with Nirvanameet.
          </p>
          <NavLink
            to={
              login
                ? haspartner.havedetail
                  ? "/home"
                  : "/partner"
                : "/register"
            }
          >
            {login
              ? haspartner.havedetail
                ? "Dashboard"
                : "Become Partner"
              : "Register"}
          </NavLink>
        </div>
      </div>
      {!login || !haspartner.havedetail ? (
        <>
          {!login ? (
            <div className="row">
              <h2>Register for New Partner</h2>
              <div className="column">
                <div className="card">
                  <img src={image2} alt="" className="image1" />
                  <p>Get exclusive information</p>
                  <p>tailored to your Customer</p>
                </div>
                <div className="card">
                  <img src={image3} alt="" className="image2" />
                  <p>Access innovative tools</p>
                  <p>and collaborative process</p>
                </div>
                <div className="card">
                  <img src={image4} alt="" className="image3" />
                  <p>Drive growth and</p>
                  <p>develop new business</p>
                </div>
              </div>
              <div className="buttons">
                <NavLink to="/login">Login</NavLink>
                <NavLink to="/register">Register</NavLink>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="homes2">
            <h4>Become an Nirvanamet Partner</h4>
            <div className="center">
              <div className="left">
                <h5>Why Partner with Nirvanameet</h5>
                <p>
                  Become an Elite Partner selling Make in India Video
                  conferencing solution-Nirvanameet to your customers and make
                  upto 40% Margin.
                  <br />
                  Access all resources, register your Deal and use our technical
                  team to arrange POC/DEMO for your customers and many more.
                </p>
                <NavLink className="button" to="/partner">
                  Apply
                </NavLink>
              </div>
              <div className="right">
                <img src={image5} alt="" height="220" />
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <Foot />
    </div>
  );
}

export default Home;
