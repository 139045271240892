import React from "react";
import Foot from "../screen/home/foot/foot";
import Head from "../screen/home/head/head";
import "./privacyPolicy.css";

// import { Container } from './styles';

function PrivacyPolicy() {
  return (
    <>
      <Head hide={false} home={true} />
      <div className="registers">
        <div className="privacy-list">
          <h1>Nirvanameet Partner Privacy Policy</h1>
          <hr />
          <ol>
            <li>
              To register as an Indirect Channel Partner with Nirvanameet, your
              company must accept the terms and conditions of this Indirect
              Channel Partner Agreement (the "Agreement"). This Agreement
              applies to all “Registered Partners”, as defined in Part A below.
            </li>
            <li>
              This Agreement is entered into by and between the company you
              identified in the applicable Partner Registration Application
              ("Registered Partner") and Nirvanameet. For purposes of this
              Agreement, Nirvanameet is defined as follows:
            </li>
            <li>
              f Registered Partner’s principal place of business is located in
              India, “Nirvanameet” is defined as Nirvanameet
            </li>
            <li>
              If Registered Partner’s principal place of business is located in
              China (excluding Hong Kong, Macau and Taiwan), for innovative
              businesses categories offered by “Nirvanameet” is defined as
              Nirvanameet.
            </li>
            <li>
              If a Registered Partner’s principal place of business is located
              in Japan, “Nirvanameet” is defined as Nirvanameet.
            </li>
            <li>
              If a Registered Partner’s principal place of business is located
              in Latin America (excluding Brazil) or the Caribbean or the United
              States of America (the “United States”), “Nirvanameet” is defined
              as Nirvanameet.
            </li>
            <li>
              If a Registered Partner’s principal place of business is located
              in Brazil, for Products and Services offered by Niladvanatge
              Technologies Pvt Ltd. in the Territory, “Nirvanameet” is defined as
              Nirvanameet.
            </li>
            <li>
              If a Registered Partner’s principal place of business is located
              in Brazil, for Products and Services offered by Nirvanameet
              Comércio e Serviços de Hardware e Software do Brasil Ltda. in the
              Territory, “Nirvanameet” is defined as Nirvanameet.
            </li>
            <li>
              If Registered Partner’s principal place of business is located in
              India, “Nirvanameet” is defined as Niladvantage Technologies
              Private Limited., a company incorporated under the provisions of
              the Companies Act, 1956 and having its operating office at
              91Springboard 175 &176 Fl 5, Bannerghatta Main Rd, Dollar Layout,
              Bengaluru, Karnataka India and Registered office at B109, CVK Meenakshi Elegance
              MLA Layout, Kalena Agharara Banerghatta Road 560076 Banglore Karnataka
            </li>
            <li>
              If a Registered Partner’s principal place of business is located
              in the Netherlands, “Nirvanameet” is defined as Nirvanameet.
            </li>
            <li>
              If Registered Partner’s principal place of business is located in
              Israel, the Asia Pacific region (excluding Australia, China,
              India, Republic of Korea, and Japan), or the Middle East, Africa,
              Central and Eastern Europe (excluding Switzerland, the
              Netherlands, the Russian Federation and the member states of the
              European Economic Area), “Nirvanameet” is defined as Nirvanameet.
            </li>
            <li>
              If Registered Partner’s principal place of business is located in
              Korea, “Nirvanameet” is defined as Nirvanameet.
            </li>
            <li>
              This Agreement shall become effective as of the date that
              Nirvanameet accepts the registration via email to the Registered
              Partner (the "Effective Date").
            </li>
            <li>
              If Nirvanameet and Registered Partner (together, the “Parties”)
              have a Direct Resale Agreement (as defined below) that is in
              effect as of the day Registered Partner submits this Agreement, or
              if the Parties subsequently execute a Direct Resale Agreement, to
              the extent that such Direct Resale Agreement conflicts with this
              Agreement, the conflicting terms and conditions of the Direct
              Resale Agreement shall take precedence for the term of the Direct
              Resale Agreement. If no Direct Resale Agreement exists, this
              Agreement comprises the complete agreement between the Parties
              concerning the subject matter herein and replaces any prior oral
              or written communications between the Parties, all of which are
              excluded. There are no other conditions, understandings,
              agreements, representations, or warranties, expressed or implied,
              which are not specified herein. This Agreement may only be
              modified by a written document executed by Niladvantage Technologies and
              Registered Partner, subject to Part B22.5 (Enforceability) below.
            </li>
            <li>
              <b>Part A. Definitions.</b>
              <ol type="a" className="sub-list">
                <li>
                  Added Value is the non-Nirvanameet component or portion of the
                  total solution which Registered Partner provides to End Users.
                  Examples of Added Value are pre- and post-sales network
                  design, configuration, trouble-shooting, managed services,
                  cloud services, and support and the sale of complementary
                  products and services that comprise a significant portion of
                  the total revenues received by Registered Partner from an End
                  User of Nirvanameet Products. Registered Partner acknowledges
                  that providing financing options and/or network services
                  (unless such network services comprise managed and/or cloud
                  services) to End Users does not constitute Added Value.
                </li>
                <li>
                  Authorized Source means a distributor that is authorized by
                  Nirvanameet to redistribute Products and Services within the
                  Territory (or within another country of Nirvanameet’s choice,
                  in the event that no Nirvanameet authorized distributor exists
                  within the Territory) to Registered Partner, as they are from
                  time to time identified. If Registered Partner is a
                  Nirvanameet Learning Partner, Authorized Source means a
                  Nirvanameet authorized Learning Partner source. If a Registered Partner
                  enters into this Agreement as a Learning Partner, it is for
                  the sole purpose of purchasing and distributing Nirvanameet’s
                  Collaborative Knowledge Product.
                </li>
                <li>
                  Nirvanameet-Branded means Products or Services bearing a valid
                  Nirvanameet trademark or service mark.
                </li>
                <li>
                  Direct Resale Agreement means Nirvanameet's System Integrator
                  Agreement, Two-Tier Distributor Agreement or any substantially
                  similar Nirvanameet contract with a different title that
                  authorizes Registered Partner to purchase Products and
                  Services directly from Nirvanameet and Resell them to End
                  Users either directly or indirectly. “Direct Resale Agreement”
                  does not include the Internet Commerce Agreement
                </li>
                <li>
                  End User is the final purchaser or licensee that: has acquired
                  Product, managed services, and/or Services for its own
                  Internal Use and not for Resale, remarketing or distribution,
                  and is identified as such purchaser or licensee by Registered
                  Partner pursuant to Part B.3.1 below.
                </li>
                <li>
                  End User Obligations means the compliance obligations of End
                  Users when purchasing Services in addition to End User
                  responsibilities set out in the applicable Services
                  Descriptions.
                </li>
                <li>
                  Internal Use is any business use of a Product for an End
                  User's or Registered Partner’s own internal use; it is to be
                  distinguished from the definition of Resale provided below.
                  For clarification purposes, “internal use” does not mean the
                  use of a Product or Service by Registered Partner for the
                  purpose of providing managed or cloud services to an End User.
                </li>
                <li>
                  Marks means the Nirvanameet Registered Partner logo, the
                  Nirvanameet Certified Partner marks for which Registered
                  Partner qualifies and has been approved by Nirvanameet, and
                  any other Nirvanameet program or certification mark for which
                  Registered Partner qualifies and has been approved by
                  Nirvanameet. “Marks” expressly excludes any other Nirvanameet
                  trademark, service mark, name, or logo. The Marks and the
                  applicable qualification requirements are delineated at
                  Nirvanameet’s web site: https://www.nirvanameet.com/.
                </li>
                <li>
                  Non-Genuine Products are any and all products:
                  <ol type="i" className="sub-list">
                    <li>
                      to which a Mark or other Nirvanameet trademark or service
                      mark has been affixed without Niladvanatge Technologies express
                      written consent;
                    </li>
                    <li>
                      that have not been manufactured by Nirvanameet Technologies, 
                      (“CTI”) or by a licensed manufacturer of either Nirvanameet or 
                      CTI in accordance with the applicable license;
                    </li>
                    <li>
                      are produced with the intent to counterfeit or imitate a
                      genuine Nirvanameet Product, or
                    </li>
                    <li>
                      Products where any form of copyright notice, trademark,
                      logo, confidentiality notice, serial number or other
                      product identifier have been removed, altered, or
                      destroyed.
                    </li>
                  </ol>
                </li>
                <li>
                  Products means the Nirvanameet hardware products, Software,
                  and related documentation which Nirvanameet makes available to
                  Registered Partner through an Authorized Source for Resale
                  (or, in the case of Software, license grant to use such
                  Software).
                </li>
                <li>
                  Professional Services means any pre- or post-sale services
                  performed by Registered Partner for an End User, excluding
                  training on Nirvanameet Products, which provides Added Value
                  for Nirvanameet Products. Such services include without
                  limitation pre- and post-sales network design, configuration,
                  troubleshooting, management (remote/virtual or on premise),
                  and support on Nirvanameet Products.
                </li>
                <li>
                  Professional Service Providers are Registered Partners that
                  wish to provide their own pre- and/or post-sales Professional
                  Services to End Users.
                </li>
                <li>
                  Registered Partner means Professional Service Providers and/or
                  Resellers (including managed services / cloud providers) that
                  have registered using the Nirvanameet Partner Registration
                  Tool and accepted the terms and conditions of this Indirect
                  Channel Partner Agreement.
                </li>
                <li>
                  Resale includes any of the following sales or dispositions of
                  a Product or Service:
                  <ol type="a" className="sub-list">
                    <li>
                      transfer of title (or, for Software, a license conferring
                      the right to use the Software, and, for Services, the
                      entitlement to receive such Services) to the End User of
                      such Product or Service;
                    </li>
                    <li>
                      transfer of title (or, for Software, a license conferring
                      the right to use the Software, and, for Services, the
                      entitlement to receive such Services) to a financial
                      intermediary such as a leasing company, even if such
                      leasing company is affiliated with Registered Partner,
                      where the Product or Service is used by an unaffiliated
                      End User; or
                    </li>
                    <li>
                      retention of title (or, for Software, a license conferring
                      the right to use the Software, and, for Services, the
                      entitlement to receive such Services) by the Registered
                      Partner, but only where the Product or Service is deployed
                      to facilitate the provision by the Registered Partner of
                      hosting, outsourcing, managed services, cloud services, or
                      any other provisioned services for the use of
                    </li>
                    <li>
                      End Users who are not affiliated with the Registered
                      Partner and who contract with the Registered Partner for
                      the provision of such services.
                    </li>
                    <li>
                      In no event shall the term Resale include use of a Product
                      or Service for the provision of network services to the
                      general public. The verb "Resell" means to engage in
                      Resale. For clarification purposes, use of a Product or
                      Service by a Registered Partner for the purpose of
                      providing managed or cloud services to an End User does
                      not constitute network services.
                    </li>
                  </ol>
                </li>

                <li>
                  Reseller is a Registered Partner that purchases and/or
                  licenses Services and Products from an Authorized Source and
                  Resells them directly to End Users.
                </li>
                <li>
                  Services means one or more of the Nirvanameet Branded Services
                  and Collaborative Services made available under the
                  Nirvanameet Services Partner Program (“Program”), further
                  described in Attachment A to this Agreement.
                </li>
                <li>
                  Service Description means the description of Services, as of
                  the purchase date of such Services, to be made available by
                  Nirvanameet, and the terms and conditions under which those
                  Services are provided.
                </li>
                <li>
                  Software is the machine-readable (object code) version of
                  computer programs developed or marketed by Nirvanameet,
                  including firmware and any related documentation.
                </li>
                <li>
                  Territory means the country identified by Registered Partner
                  in the applicable Partner Registration Application accepted by
                  Nirvanameet. If that country resides within a Country Group,
                  the Registered Partner’s Territory shall include all countries
                  within the associated Country Group.
                </li>
                <li>
                  Unauthorized Nirvanameet Product means any genuine Nirvanameet
                  Product or Nirvanameet Service that has been purchased or
                  acquired, either directly or indirectly, from any party other
                  than Nirvanameet and/or an Authorized Source or sold to any
                  party other than an End User. Unauthorized Nirvanameet
                  Products do not include Non-Genuine Products.
                </li>
              </ol>
            </li>
            <li>
              <b>
                Part B. Registered Partner Terms and Conditions. Nirvanameet
                Authorization and Resale Rules.
              </b>
              <ol className="sub-list">
                <li>
                  Nirvanameet Authorization. Subject to the terms and conditions
                  set forth in this Agreement, and during this Agreement’s term,
                  as set forth below, Niladvanatge Technologies authorizes Registered Partner
                  to purchase and/or license Services and Products only from an
                  Authorized Source, and to Resell and/or redistribute such
                  Services and Products directly to End Users within the
                  Territory. “Within the Territory” means that End Users must
                  deploy the Products and/or receive the Services within the
                  Territory.To assist Registered Partner in its sales and
                  marketing efforts, Registered Partner may also purchase and/or
                  license Services and Products for its purchases of
                  demonstration, evaluation, and lab equipment. Registered
                  Partners may only use such Services and Products for
                  demonstration, evaluation, or lab purposes. Except to the
                  extent permitted by Applicable Law, any Software received with
                  or for such Products may not be distributed further, and,
                  notwithstanding any other provision of this Agreement, all
                  Software for such Products is licensed to Registered Partner
                  solely for its use for demonstration, evaluation, or lab
                  purposes.
                </li>
                <li>
                  No Resale Outside the Territory. Registered Partner agrees not
                  to solicit Product or Service orders, engage salespersons,
                  Resell, or establish warehouses or other distribution centers
                  outside of the Territory. For purposes of clarification,
                  Nirvanameet considers the following to be soliciting Product
                  or Service orders outside of the Territory in violation of the
                  Agreement, except as expressly authorized by Nirvanameet in
                  writing in advance: where Registered Partner solicits for
                  Resale or Resells Nirvanameet Product or Services to an End
                  User that is located outside the Territory and otherwise has
                  no meaningful operations in the Territory, even if delivery of
                  the Nirvanameet Product or Service occurs in the Territory.
                  1.3 Sales to End Users. Registered Partner certifies that it
                  is acquiring the Products and Services solely for Resale to
                  End Users, in accordance with this Agreement. Registered
                  Partner will not Resell, license, sublicense or distribute
                  Products or Services to other Registered Partners of
                  Nirvanameet Products or Services, whether or not such other
                  Registered Partners are authorized by Niladvanatge or by any
                  other source to Resell or license Products or Services.
                  Notwithstanding the above provisions of this Part
                </li>{" "}
                <li>
                  Registered Partner may Resell Products or Services to any
                  other Nirvanameet-authorized Registered Partner of Nirvanameet
                  Products or Services in the Territory, provided that such
                  other Registered Partner is purchasing and using such Products
                  or Services strictly as an End User and strictly for its
                  Internal Use in the Territory. Registered Partner certifies
                  that it will not misrepresent service coverage to End Users by
                  selling Services to End Users without purchasing service
                  contract coverage for those specified items from an Authorized
                  Source.
                </li>
                <li>
                  Non-Genuine Products or Unauthorized Nirvanameet Products.
                  Registered Partner may not purchase or Resell Non-Genuine
                  Products or Unauthorized Nirvanameet Products or Resell
                  Services associated with any Non-Genuine Products or
                  Unauthorized Nirvanameet Products. If Nirvanameet determines
                  that Registered Partner has Resold and/or redistributed
                  Unauthorized Nirvanameet Products, then Nirvanameet may, at
                  Niladvanatge Technologies sole discretion:
                  <ol type="a" className="sub-list">
                    <li>
                      audit Registered Partner’s purchase and Resale records of
                      Nirvanameet Product and relevant records pursuant to Part.
                    </li>
                    <li>
                      invoice Registered Partner for all reasonable costs
                      incurred by Nirvanameet in its performance of the Audit
                      and/or
                    </li>
                    <li>
                      suspend shipments to Registered Partner. For all
                      Unauthorized or altered Nirvanameet Products, Nirvanameet
                      reserves the right to deny or withhold any Services on
                      such Products, per the non-entitlement policies referenced
                      above.
                    </li>
                  </ol>
                </li>
                <li>
                  Renewal of Services. Sixty (60) Days Prior to Service Contract
                  Expiration Date: At least sixty (60) days prior to the
                  expiration date of a Service contract, Nirvanameet, or its
                  authorized agents, may send Service contract renewal reminder
                  notices to Registered Partner and/or the identified End User,
                  and Registered Partner will either:
                  <ol type="i" className="sub-list">
                    <li>
                      initiate the Service contract renewal process with the End
                      User and forward to Nirvanameet the completed service
                      contract renewal with a valid purchase order; or
                    </li>

                    <li>
                      notify Nirvanameet in writing of Registered Partner’s
                      intent to not renew the Services. At the Service Contract
                      Expiration Date: If, upon the expiration date of the
                      Service contract, Registered Partner has not renewed the
                      Services, Nirvanameet or its authorized agents, may
                      contact the End User to arrange for the renewal of such
                      Services with Nirvanameet directly or via another
                      Nirvanameet-authorized Registered Partner.
                    </li>
                  </ol>
                </li>{" "}
                <li>
                  Unsupported Products. If Registered Partner elects not to
                  Resell Services at the time of Product purchase or if Product
                  becomes unsupported due for whatever reason at some point
                  subsequent to initial deployment, Registered Partner shall
                  refer End User information, including but not limited to End
                  User name, address and phone number to Nirvanameet within
                  ninety (90) days of Product becoming unsupported and
                  authorizes Nirvanameet to contact the End User for the express
                  purpose of contracting directly for support services for the
                  unsupported Product identified by Registered Partner. Added
                  Value Requirement. Each time a Registered Partner Resells
                  Services or Products to an End User, Registered Partner will
                  include its Added Value. Registered Partner must be able to
                  demonstrate Products to prospective End Users at the End
                  User's location and make Professional Services available for
                  each Product Resold by Registered Partner. Registered Partner
                  Obligations.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
      <Foot />
    </>
  );
}

export default PrivacyPolicy;
