//Imports of Contact form API
import axios from "axios";

//Function to create Support Ticket
const createTicket = async (query) => {
  try {
    const createTicketApi = {
      method: "post",
      url:
        process.env.REACT_APP_API_API_URL + "/nirvanadev/api/v1/support/create",
      data: {
        name: query.name,
        email: query.email,
        phone: query.phone,
        subject: query.subject,
        description: query.message,
        type: query.type,
        partner: query.ownerid,
      },
      header: {},
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    };
    const tickres = await axios(createTicketApi);
    if (!tickres.status === 200) {
      throw new Error(tickres.error);
    }
    return tickres.data;
  } catch (e) {
    if (!e.response.data) {
    } else return e.response.data;
  }
};

export { createTicket };
