import axios from "axios";

const getAllQuestions = async () => {
  try {
    const Questionconfig = {
      method: "get",
      url:
        process.env.REACT_APP_API_API_URL +
        "/nirvanadev/api/v1/partner/question/getall",
      withCredentials: true,
    };
    const res = await axios(Questionconfig);
    if (!res.status === 200) {
      throw new Error(res.Error);
    }
    return res.data;
  } catch (error) {}
};

const getPartner = async (id) => {
  try {
    const getPartnerconfig = {
      method: "get",
      url:
        process.env.REACT_APP_API_API_URL +
        "/nirvanadev/api/v1/partner/getapartner/" +
        id,
      withCredentials: true,
    };
    const res = await axios(getPartnerconfig);
    if (!res.status === 200) {
      throw new Error(res.Error);
    }
    return res.data.data;
  } catch (error) {}
};

const checkPartnerLogin = async () => {
  try {
    const checkPartnerLogin = {
      method: "get",
      url:
        process.env.REACT_APP_API_API_URL +
        "/nirvanadev/api/v1/partner/checklogin",
      withCredentials: true,
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    };
    const res = await axios(checkPartnerLogin);
    if (!res.status === 200) {
      throw new Error(res.Error);
    }
    return res.data.data;
  } catch (error) {
    return false;
  }
};

const partnerLogout = async () => {
  try {
    const partnerLogout = {
      method: "get",
      url:
        process.env.REACT_APP_API_API_URL + "/nirvanadev/api/v1/partner/logout",
      withCredentials: true,
    };
    const res = await axios(partnerLogout);
    if (!res.status === 200) {
      throw new Error(res.Error);
    }
    return true;
  } catch (error) {
    return false;
  }
};

const updatePartnerDetails = async (data) => {
  try {
    const updatePartnerDetailsConfig = {
      method: "post",
      url:
        process.env.REACT_APP_API_API_URL +
        process.env.REACT_APP_API_API_URL +
        "/nirvanadev/api/v1/partner/detail/adddetail",
      data: data,
      withCredentials: true,
    };
    const res = await axios(updatePartnerDetailsConfig);

    if (!res.status === 200) {
      throw new Error(res.Error);
    }

    return true;
  } catch (error) {
    return false;
  }
};

export {
  getAllQuestions,
  getPartner,
  checkPartnerLogin,
  partnerLogout,
  updatePartnerDetails,
};
