import axios from "axios";

const getStates = async (country) => {
  try {
    const config = {
      method: "post",
      url: "https://countriesnow.space/api/v0.1/countries/states",
      data: {
        country: country,
      },
    };
    const res = await axios(config);
    if (!res.status === 200) {
      throw new Error(res.error);
    }
    return res.data.data;
  } catch (error) {
    return false;
  }
};

const getPlansAll = async () => {
  try {
    const config = {
      method: "get",
      url: process.env.REACT_APP_API_API_URL + "/nirvanadev/api/v1/plan/getall",
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    };
    const res = await axios(config);
    if (!res.status === 200) {
      throw new Error(res.error);
    }
    return res.data.data;
  } catch (error) {
    return false;
  }
};

const registerDeals = async (data) => {
  try {
    const config = {
      method: "post",
      url:
        process.env.REACT_APP_API_API_URL +
        "/nirvanadev/api/v1/partner/deals/createdeal",
      data: data,
      withCredentials: true,
    };
    const res = await axios(config);
    if (!res.status === 200) {
      throw new Error(res.error);
    }
    console.log(res);
    return res.data.data;
  } catch (error) {
    return false;
  }
};

export { getStates, getPlansAll, registerDeals };
