import React, { useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { ImSpinner10 } from "react-icons/im";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { registerDeals } from "../../../../apis/DealRegApis";
import images from "../../../../images/dealRegNext.svg";
import "./next.css";

function Next({ data, dispatch }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [ownerdetials, setOwnerDetails] = useState({
    name: "",
    email: "",
    countryCode: "+91",
    profession: "",
  });
  useEffect(() => {
    if (!data.endcustomercompanydetails.companyname) {
      Swal.fire({
        position: "top-right",
        icon: "info",
        title: "Please Fill the Customer Company Details and Plans Deals",
        showConfirmButton: false,
        timer: 2000,
      });
      navigate("/home/dealregistration");
    }
  }, [data, navigate]);
  const changeHandler = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    await setOwnerDetails({ ...ownerdetials, [name]: value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    await dispatch({ type: "ADD_OWNERDETAILS", payload: ownerdetials });
    const res = await registerDeals(data);
    if (!res) {
      setLoading(false);
      Swal.fire({
        title: "<strong>Something Went Wrong</strong>",
        icon: "error",
        html: "Unable to Register now, Please Try Later",
        showCloseButton: true,
        confirmButtonText: "Ok",
      });
      navigate("/home/dealinfo");
      return;
    }
    setLoading(false);
    Swal.fire({
      icon: "success",
      title: "Your Deal has been Register and We will contact you soon.",
      text: "Thank You Partner",
    });
    navigate("/home/dealinfo");
  };

  return (
    <div className="deal-register">
      <img src={images} alt="" />

      <div className="nva-head">
        {!loading ? (
          <>
            <div className="nav-headers">Please Fill Customer Crenditials</div>
            <form onSubmit={submitHandler}>
              <div>
                <label>
                  Company Contact Person Name (for product delevery <b>*</b>):
                </label>
                <input
                  type="text"
                  name="name"
                  required
                  onChange={changeHandler}
                />
              </div>
              <div>
                <label>
                  Email: <b>*</b>
                </label>
                <input
                  type="email"
                  required
                  name={"email"}
                  onChange={changeHandler}
                />
              </div>
              <div>
                <label>
                  Profession: <b>*</b>
                </label>
                <select
                  id="country"
                  name="profession"
                  onChange={changeHandler}
                  required
                >
                  <option value={""}>Select One</option>
                  <option value={"Director"}>Director</option>
                  <option value={"Sales Executive"}>Sales </option>
                  <option value={"Other"}>Other</option>
                </select>
              </div>
              <div className="deal-buttons">
                <NavLink className={"button"} to="/home/dealregistration/">
                  Back
                  <BsArrowLeftShort />
                </NavLink>
                <button className={"button"}>Submit</button>
              </div>
            </form>
          </>
        ) : (
          <>
            <p style={{ marginBottom: "2rem", textAlign: "center" }}>
              Submiting Your Deal With us,Please Wait.....
            </p>
            <ImSpinner10 className="spinner" />
          </>
        )}
      </div>
    </div>
  );
}

export default Next;
