const HomeReducer = (state, action) => {
  switch (action.type) {
    case "ADD_DEALDETAIL_PART":
      state.dealdetails.partner = action.payload;
      return state;
    case "ADD_OWNERDETAILS":
      state.ownerdetials = action.payload;
      return state;
    case "ADD_CUSTOMERDETAILS":
      state.endcustomercompanydetails = action.payload;
      return state;
    case "ADD_PLAN_DETAILS":
      state.plandetails = action.payload;
      return state;
    default:
      return state;
  }
};

export default HomeReducer;
