import "./Home.css";
import React from "react";
import logo from "../../../images/logo192.png";

const Home = () => {
  return (
    <div className="Dash-Home">
      <div className="brand">
        <img src={logo} alt="" />
        <div>Welcome Partner</div>
      </div>
    </div>
  );
};

export default Home;
