const PartnerReducer = (state, action) => {
  switch (action.type) {
    case "ADD_PARTNERSHIP_TYPE":
      state.partnershipType = action.payload;
      return state;
    case "ADD_AGGREMENT":
      state.aggrement = action.payload;
      return state;
    case "ADD_CONTACT_DETAILS":
      state.contactInformation = action.payload;
      return state;
    case "ADD_COMPANY_DETAILS":
      state.companyDetails = action.payload;
      return state;
    case "ADD_NEXT_STEP":
      state.step = action.payload;
      return state;
    default:
      return state;
  }
};

export default PartnerReducer;
