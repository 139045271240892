import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { partnerLogout } from "../../../apis/PartnersAPI";
import "./head.css";
import logo from "../../../images/logo192.png";
import { RiProfileLine } from "react-icons/ri";
import {
  MdDashboard,
  MdOutlineLogout,
  MdOutlineSupportAgent,
  MdSupervisedUserCircle,
} from "react-icons/md";
import { FcHome, FcPrivacy } from "react-icons/fc";

function Head({ hide, partner, login, home, funclogin }) {
  const naviagte = useNavigate();
  const logout = async () => {
    const res = await partnerLogout();
    if (res) {
      funclogin(false);
      naviagte("/");
    }
  };
  return (
    <>
      <div className="my-header">
        <h2>
          <img src={logo} alt="" width={"50px"} />
          Nirvanameet <span>Partner Portal</span>
        </h2>
        <div className="my-icon">
          {hide ? (
            ""
          ) : home ? (
            <>
              <NavLink to="/">
                <FcHome />
                Home
              </NavLink>
            </>
          ) : (
            <NavLink to="/login">
              <FcPrivacy />
              Login
            </NavLink>
          )}
        </div>
        {login ? (
          <div className="profile-drop">
            <h4>
              <img
                className="dp"
                src={process.env.REACT_APP_API_API_URL + partner.photoUrl}
                alt=""
              />
              {partner.firstname}
            </h4>
            <div className="dp-dropdown">
              <NavLink to={"/profile"} className="dp-items" end>
                <RiProfileLine />
                Profile
              </NavLink>
              <NavLink
                to={partner.havedetail ? "/home" : "/partner"}
                className="dp-items"
                end
              >
                {partner.havedetail ? (
                  <>
                    <MdDashboard />
                    Dashboard
                  </>
                ) : (
                  <>
                    <MdSupervisedUserCircle />
                    Become Partner
                  </>
                )}
              </NavLink>
              {partner.havedetail ? (
                <NavLink
                  to={partner.genuine ? "/home/support" : "/contactus"}
                  className="dp-items"
                  end
                >
                  <MdOutlineSupportAgent />
                  Support
                </NavLink>
              ) : (
                <NavLink to="/contactus" className="dp-items" end>
                  <MdOutlineSupportAgent />
                  Support
                </NavLink>
              )}
              <button onClick={logout} className="dp-items">
                <MdOutlineLogout /> Logout
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Head;
