import React from "react";
import { NavLink } from "react-router-dom";
import Head from "../../home/head/head";
import "./Header.css";

const Header = ({ partner, funclogin, logedin }) => {
  return (
    <>
      <Head
        partner={partner}
        funclogin={funclogin}
        login={logedin}
        hide={logedin ? true : false}
      />
      <div className="my-home">
        <div className="drop-header">
          <NavLink className={"drop-item"} to={"/home"} id="p1">
            Home
          </NavLink>
        </div>
        <div className="drop-header">
          <p id="p1">Product and Services</p>
          <div className="dropdown">
            <NavLink className={"drop-item"} to="/home/features">
              Features
            </NavLink>
          </div>
        </div>
        <div className="drop-header">
          <p id="p1">Sales & Orders</p>
          <div className="dropdown">
            <NavLink className={"drop-item"} to="/home/dealinfo">
              Deal Registration
            </NavLink>
            <NavLink className={"drop-item"} to="/home/managedeals">
              All Deals
            </NavLink>
          </div>
        </div>
        {/* <div className="drop-header">
          <p id="p1">Marketing and Demand Gen</p>
          <div className="dropdown">
            <NavLink className={"drop-item"} to="/home/dealinfo">
              Email Marketting
            </NavLink>
          </div>
        </div> */}

        <div className="drop-header">
          <NavLink className={"drop-item"} to={"/home/support"} id="p1">
            Support
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Header;
