import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./companyDetails.css";

function CompanyDetails({ dispatch }) {
  const navigate = useNavigate();
  const [contact, setContact] = useState({
    authorizedSignatoryFirstName: "",
    authorizedSignatoryLastName: "",
    buisnessEmail: "",
    phone: "",
    Fax: "",
    jobRole: "",
    jobLevel: "",
    countrycode: "+91",
  });
  const [companyDetail, setCompanyDetail] = useState({
    fullCompanyName: "",
    address: "",
    city: "",
    country: "",
    state: "",
    pincode: "",
    countryCode: "+91",
    companyPhone: "",
    companyDescription: "",
    companyWebAddress: "",
    companyEmailDomain: "",
    vatRegistrationNumber: "",
    gstin: "",
    panNo: "",
    cin: "",
    promotionEmails: "",
  });
  const handleCompanyDetails = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "promotionEmails")
      if (value === "yes") setCompanyDetail({ ...companyDetail, [name]: true });
      else setCompanyDetail({ ...companyDetail, [name]: false });
    else setCompanyDetail({ ...companyDetail, [name]: value });
  };
  const handleContactDetails = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setContact({ ...contact, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch({ type: "ADD_CONTACT_DETAILS", payload: contact });
      await dispatch({ type: "ADD_COMPANY_DETAILS", payload: companyDetail });
      await dispatch({ type: "ADD_NEXT_STEP", payload: 2 });
      navigate("/partner/agreement");
    } catch (error) {}
  };
  return (
    <>
      <form className="all-contains" onSubmit={handleSubmit}>
        <div className="first-contain">
          <p>Owerner of the Company Contact Information</p>
        </div>
        <div>
          <div className="my-container">
            <div className="form-control">
              <label>
                First Name <b>*</b>
              </label>
              <input
                type="text"
                placeholder="Name"
                name="authorizedSignatoryFirstName"
                onChange={handleContactDetails}
                required
              />
            </div>
            <div className="form-control">
              <label>
                Last Name <b>*</b>
              </label>
              <input
                type="text"
                placeholder="Name"
                name="authorizedSignatoryLastName"
                onChange={handleContactDetails}
                required
              />
            </div>
            <div className="form-control">
              <label>
                Business Email <b>*</b>
              </label>
              <input
                type="email"
                placeholder="Business Email"
                name="buisnessEmail"
                onChange={handleContactDetails}
                required
              />
            </div>
            <div className="form-control">
              <label>
                Phone <b>*</b>
              </label>
              <input
                type="number"
                placeholder="Phone"
                name="phone"
                onChange={handleContactDetails}
                required
              />
            </div>
          </div>
          <div className="my-container">
            <div className="form-control">
              <label>Fax</label>
              <input
                type="number"
                placeholder="Phone"
                name="Fax"
                onChange={handleContactDetails}
              />
            </div>
            <div className="form-control">
              <label>
                Job Role <b>*</b>
              </label>
              <select
                id="country"
                name="jobRole"
                onChange={handleContactDetails}
                required
                defaultValue=""
              >
                <option value="" disabled>
                  Select One
                </option>
                <option value="Director">Director</option>
                <option vlaue="Sales">Sales</option>
                <option vlaue="Other">Other</option>
              </select>
            </div>
            <div className="form-control">
              <label>
                Job Level <b>*</b>
              </label>
              <select
                id="country"
                name="jobLevel"
                onChange={handleContactDetails}
                required
                defaultValue=""
              >
                <option value="" disabled>
                  Select One
                </option>
                <option value="Director / Senior Manager">
                  Director / Senior Manager
                </option>
                <option value="Softawre Engineer">Softawre Engineer</option>
                <option value="Sales">Sales</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
        </div>
        <div className="first-contain">
          <p>Business Information [Please complete the information below]:</p>
        </div>
        <div>
          <div className="my-container">
            <div className="form-control address">
              <label>
                Company Name <b>*</b>
              </label>
              <input
                type="text"
                placeholder="Name"
                name="fullCompanyName"
                onChange={handleCompanyDetails}
                required
              />
            </div>
            <div className="form-control address">
              <label>
                Address 1 <b>*</b>
              </label>
              <input
                type="text"
                placeholder="Address"
                name="address"
                onChange={handleCompanyDetails}
                required
              />
            </div>
            <div className="form-control address">
              <label>Address 2 </label>
              <input
                type="text"
                placeholder="Alternate Address"
                name="address"
                onChange={handleCompanyDetails}
              />
            </div>
          </div>
          <div className="my-container">
            <div className="form-control">
              <label>Address 3 </label>
              <input
                type="text"
                placeholder="Alternate Address"
                name="address"
                onChange={handleCompanyDetails}
              />
            </div>
            <div className="form-control ">
              <label>
                City <b>*</b>
              </label>
              <input
                type="text"
                placeholder="city"
                name="city"
                onChange={handleCompanyDetails}
                required
              />
            </div>
            <div className="form-control">
              <label>
                State/Province <b>*</b>
              </label>
              <input
                type="text"
                placeholder="State"
                name="state"
                onChange={handleCompanyDetails}
                required
              />
            </div>
          </div>
          <div className="my-container">
            <div className="form-control">
              <label>
                Country/Region <b>*</b>
              </label>
              <input
                type="text"
                placeholder="country"
                name="country"
                onChange={handleCompanyDetails}
                required
              />
            </div>
            <div className="form-control">
              <label>
                Postal Code <b>*</b>
              </label>
              <input
                type="number"
                placeholder="Postal Code"
                name="pincode"
                onChange={handleCompanyDetails}
                required
              />
            </div>
            <div className="form-control">
              <label>
                Company Phone Number <b>*</b>
              </label>
              <input
                type="number"
                placeholder="Phone number"
                name="companyPhone"
                onChange={handleCompanyDetails}
                required
              />
            </div>
          </div>
          <div className="my-container">
            <div className="form-control">
              <label>
                Company Description <b>*</b>
                <p>(Max 200 Characters)</p>{" "}
              </label>
              <input
                type="text"
                placeholder="Description"
                name="companyDescription"
                onChange={handleCompanyDetails}
              />
            </div>
            <div className="form-control">
              <label>
                Company Web Address <b>*</b>
              </label>
              <input
                type="text"
                placeholder="Enter Web Address"
                name="companyWebAddress"
                onChange={handleCompanyDetails}
                required
              />
            </div>
            <div className="form-control">
              <label>
                Company's Email Domain <b>*</b>
              </label>
              <input
                type="text"
                placeholder="Business Email Domain"
                name="companyEmailDomain"
                onChange={handleCompanyDetails}
                required
              />
            </div>
          </div>
          <div className="my-container">
            <div className="form-control">
              <label>
                PAN Number <b>*</b>
              </label>
              <input
                type="text"
                placeholder="PAN"
                name="panNo"
                required
                onChange={handleCompanyDetails}
              />
            </div>
            <div className="form-control">
              <label>
                GST Number <b>*</b>
              </label>
              <input
                type="text"
                required
                placeholder="GSTIN"
                name="gstin"
                onChange={handleCompanyDetails}
              />
            </div>
            <div className="form-control">
              <label>
                Corporate Identification Number <b>*</b>
              </label>
              <input
                type="text"
                placeholder="CIN"
                name="cin"
                required
                onChange={handleCompanyDetails}
              />
            </div>
          </div>
        </div>
        <div className="first-contain">
          <p>Niladvantage communication</p>
        </div>
        <p>
          If you select 'Yes' you will always have the opportunity to choose
          only the notifications you would like to receive related to
          Nirvanameet VC Solution. You have the ability to unsubscribe to
          selected notification at any time. Your personal information will be
          used in accordance with{" "}
          <a href="/register">Niladvantage privacy statement</a>
        </p>
        <p>
          I would like to receive Niladvantage Communiction by email <b>*</b>
        </p>

        <div className="second-contain">
          <div className="form-control">
            <div className="radio">
              <label>Yes</label>
              <input
                type="radio"
                onChange={handleCompanyDetails}
                value="yes"
                required
                name="promotionEmails"
              />
            </div>
            <div className="radio">
              <label>No</label>
              <input
                type="radio"
                onChange={handleCompanyDetails}
                value="no"
                required
                name="promotionEmails"
              />
            </div>
          </div>
        </div>
        <div className="my-button">
          <input className="button" type="submit" value="Continue" />
          <Link className="button" to="/">
            Cancel
          </Link>
        </div>
      </form>
    </>
  );
}

export default CompanyDetails;
