import React, { useState } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Head from "../home/head/head";
import Foot from "../home/foot/foot";
import loginImg from "../../images/hero-img.png";
import { getPartner } from "../../apis/PartnersAPI";
import Swal from "sweetalert2";
import {
  MdAdminPanelSettings,
  MdLock,
  MdLogin,
  MdMail,
  MdOutlineResetTv,
} from "react-icons/md";

function Login() {
  const naviagte = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginuser = async (e) => {
    e.preventDefault();
    try {
      const loginPartner = {
        method: "post",
        url:
          process.env.REACT_APP_API_API_URL +
          "/nirvanadev/api/v1/partner/signin",
        data: {
          email: email,
          password: password,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
        withCredentials: true,
        credentials: "include",
      };
      const rest = await axios(loginPartner);

      if (!rest.status === 200) {
        throw new Error(rest.Error);
      }
      const getPartnerDetails = await getPartner(rest.data.data._id);
      if (getPartnerDetails.emailVerified) {
        if (!getPartnerDetails.havedetail) {
          naviagte("/partner");
        } else if (!getPartnerDetails.genuine) {
          naviagte("/verifier");
        } else {
          naviagte("/home");
        }
      } else naviagte("/verify", { state: getPartnerDetails });
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops... Status " + error.response.status,
          text: error.response.data.msg,
        });
      }
    }
  };
  return (
    <>
      <Head hide={false} home={true} />
      <form className="login-container" onSubmit={loginuser}>
        <div className="container1">
          <div className="left">
            <h2>Partner Sign In</h2>
            <div className="divider-hr"></div>
            <div className="form-control-login">
              <label>
                <MdMail />
                Email Address <b>*</b>
              </label>
              <input
                type="email"
                placeholder="Enter Email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-control-login">
              <label>
                <MdLock />
                Password <b>*</b>
              </label>
              <input
                type="password"
                placeholder="Enter Password"
                name="password"
                id="psw"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="logbtn">
              <MdLogin />
              Login
            </button>{" "}
            <Link style={{ marginTop: "-1rem" }} to="/resetpassword">
              <MdOutlineResetTv />
              Click Here to Reset Password
            </Link>
            <h3>Don`t have an account</h3>
            <Link to="/register">
              <MdAdminPanelSettings />
              Request access to the NirvanaMeet Partner Portal
            </Link>
          </div>
          <div className="right">
            <img src={loginImg} alt="" />
          </div>
        </div>
      </form>
      <Foot />
    </>
  );
}

export default Login;
