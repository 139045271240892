import React from "react";
import { BsBoxArrowInUpRight } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import "./dealHome.css";

function DealRegistrations() {
  return (
    <div className="deal-header">
      <div className="deal-body">
        <div className="first-deal">
          <p>Deal Registration</p>
        </div>
        <h6>Deal Register Your Opportunity</h6>
        <p>
          Nirvanameet is committed to rewarding you for driving new business.
          The Deal
        </p>
        <p>
          Registration program drives predictable engagement for partners who
          actively promote
        </p>
        <p>
          Nirvanameet VC Solutions to their customers, facilitating engagement
          and colaboration with
        </p>
        <p>
          Nirvanameet cores sales teams. When you register approved
          opportunities, you may receive
        </p>
        <p>increment benefits including:</p>
        <p>1. Advantage Pricing</p>
        <p>2. Increased incentives eligibility</p>
        <p>
          If you are new, See our guide below to get started, asa well as the
        </p>
        <NavLink to="/">
          <p>Deal Registration Terms</p>
        </NavLink>
      </div>
      <div className="deal-button">
        <NavLink className="deal-link" to="/home/dealregistration">
          <BsBoxArrowInUpRight />
          Register a new Deal
        </NavLink>
        <p>1. Click Register to Nirvanameet new Deal</p>
        <p>2. Register a Nirvanameet VC Solution Deal</p>
      </div>
    </div>
  );
}

export default DealRegistrations;
