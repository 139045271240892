import React from "react";
import "./footer.css";
import Foot from "../../foot/foot";
// import { Container } from './styles';

function Footer() {
  return (
    <>
      <Foot />
    </>
  );
}

export default Footer;
