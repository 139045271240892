import React, { useEffect } from "react";
import "./header.css";
import Head from "../../head/head";
import { NavLink } from "react-router-dom";

function Header({ step, partner, logedin, funclogin }) {
  useEffect(() => {
    document.querySelectorAll(".steps").forEach((e, index) => {
      if (index < step) {
        e.classList.add("disabled");
      } else {
        e.classList.remove("disabled");
      }
      if (e.textContent === "Submit") {
        e.classList.add("disabled");
      }
    });
  }, [step]);
  return (
    <>
      <Head
        partner={partner}
        funclogin={funclogin}
        login={logedin}
        hide={logedin ? true : false}
      />
      <div className="my-headres">
        <img
          src="https://www.casagrand.co.in/wp-content/themes/casagrand2018/images/jv-banner-1.jpg?ver=1.200"
          alt=""
          height="400"
        />
        <h2>Partner Registration</h2>
      </div>
      <div className="mini-header">
        <NavLink className="steps" to="/partner" end>
          Partnership Type
        </NavLink>
        <NavLink className="steps" to="/partner/company" end>
          Company Details
        </NavLink>
        <NavLink className="steps" to="/partner/agreement" end>
          Agreement
        </NavLink>
        <NavLink className="steps" to="/partner/submission">
          Submit
        </NavLink>
      </div>
    </>
  );
}

export default Header;
