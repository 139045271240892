import React, { useEffect, useState } from "react";
import "./Features.css";
import vc_1 from "../../../images/Vc-F-1.png";
import roomimage from "../../../images/meeting_room.png";
import bulkimage from "../../../images/Bulk_img.png";
import singleimage from "../../../images/single_img.png";
import broucher from "../../../images/broucher.png";
import download_app from "../../../images/download_app.png";
import { FaDownload, FaGooglePlay } from "react-icons/fa";

const Features = () => {
  const [brocher, setBrocher] = useState([]);
  useEffect(() => {
    const generatePdf = async () => {
      const pdfcommonread = await fetch(
        process.env.REACT_APP_API_API_URL +
          "/nirvana/api/pic/partner/pdf/static/Brochure.pdf"
      ).then((res) => res.arrayBuffer());
      const bytes = new Uint8Array(pdfcommonread);
      const blob = new Blob([bytes], { type: "application/pdf" });
      const docUrl = URL.createObjectURL(blob);
      setBrocher(docUrl);
    };
    generatePdf();
  }, []);
  return (
    <>
      <div className="sect-features">
        <div className="left">
          <p>
            Niladvantage Technologies Pvt Ltd is a start-up IT-solutions and
            System integration company, headquarter in Bangalore, India. We have
            Video Conferencing Solution referred as <b>"Nirvana-Meet"</b>,
            developed in India under <b>Digital India Initiative</b>.
          </p>
        </div>
        <div className="right">
          <img src={vc_1} alt="" />
        </div>
      </div>
      <div className="mid-sect">
        <p>Our Services</p>
        <div className="center">
          <div className="service-card">
            <img src={roomimage} alt="" />
            <div className="card-body">
              <h2>Room Integration</h2>
              <p>
                Get Connected your Room Conferencing Module Device System With
                our conferencing sollution. Become our Partner for Integrating.
              </p>
            </div>
          </div>
          <div className="service-card">
            <img src={bulkimage} alt="" />
            <div className="card-body">
              <h2>Bulk Licences</h2>
              <p>
                We Sale Bulk Licences for Industries and Corporates With
                Discounts Through our Reseller Partners. We Consider per order a
                Bulk Licences Purchase, If purchase is greater than 15 Licences.
              </p>
            </div>
          </div>
          <div className="service-card">
            <img src={singleimage} alt="" />
            <div className="card-body">
              <h2>Single Licence</h2>
              <p>
                We also Sale Single Licence for Consumer With Discount Directly
                from the Website. Also From Reseller Partners.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="download-sect">
        <p>Downloads</p>
        <div className="center-download">
          <div className="left">
            <img src={broucher} alt="" />
            <div className="card-body">
              <a className="button" href={brocher} download>
                <FaDownload size={"30px"} />
                <div>
                  <span>Download</span>
                  <br /> Broucher
                </div>
              </a>
            </div>
          </div>
          <div className="right">
            <img src={download_app} alt="" />
            <div className="card-body">
              <a
                className="button"
                href={
                  "https://play.google.com/store/apps/details?id=nirvana.niladvantage.nirvanameet"
                }
              >
                <FaGooglePlay size={"30px"} />
                <div>
                  <span>Get It On</span> <br />
                  Play Store
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
