import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./component/screen/homepage/homepage";
import Register from "./component/screen/register/register";
import Home from "./component/screen/home/home";
import Login from "./component/screen/login/login";
import Verify from "./component/screen/verify/verify";
import PartnerDash from "./component/screen/home/partnerRegistration/PartnerDash";
import ProfilePage from "./component/profile/profilePage";
import Feedback from "./component/feedback/feedback";
import PrivacyPolicy from "./component/PrivacyPolicy/privacyPolicy";
import Contactus from "./component/contactus/Contact_us";
import GenuineVerifierPAge from "./component/GenuineVerifierPage";
import backgroundImage from "./component/images/DealBAckground.png";
import "./App.css";
import ResetPassword from "./component/screen/resetpassword/ResetPassword";
import logo from "./component/images/logo192.png";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.querySelectorAll("input[type=number]").forEach((e) => {
      e.onwheel = function (event) {
        event.target.blur();
      };
    });
  }, []);
  return (
    <>
      <div
        className="homepage-inner"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="homepage-mobile">
          <div className="message-box">
            <img src={logo} width="100px" alt="" />
            <h1>NirvanaMeet Partner Portal</h1>
            <h4>Sorry For Inconvinience</h4>
            <p>
              this Portal is under Developement for Mobile Version <br />
              <br />
              Currently Supports: Desktops,Laptops and Tablets
            </p>
          </div>
        </div>
        <div className="homepage">
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/home//*" element={<HomePage />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/verify" element={<Verify />} />
              <Route exact path="/partner//*" element={<PartnerDash />} />
              <Route exact path="/feedback" element={<Feedback />} />
              <Route exact path="/privacy" element={<PrivacyPolicy />} />
              <Route exact path="/profile" element={<ProfilePage />} />
              <Route exact path="/contactus" element={<Contactus />} />
              <Route exact path="/verifier" element={<GenuineVerifierPAge />} />
              <Route exact path="/resetpassword" element={<ResetPassword />} />
            </Routes>
          </BrowserRouter>
        </div>
      </div>
    </>
  );
}

export default App;
